const DirectusAssetURL = 'https://cm-marketing.directus.app/assets'

/**
 * 
 * @param {string} id 
 * @returns {string}
 */
function getDirectusAsset(id) {
  if (typeof id !== 'string') {
    console.error(`asset id needs to be a string but got: ${typeof id} with value: ${id}`)
    return '/'
  }

  return `${DirectusAssetURL}/${id}`
}

/**
 * 
 * @param {string} id 
 * @returns {string}
 */
export function getTourHeaderImage(id) {
  return `${getDirectusAsset(id)}?width=1920&height=730&format=auto&quality=60`
}

/**
 * 
 * @param {string} id 
 * @returns {string}
 */
export function getTourImage(id) {
  return `${getDirectusAsset(id)}?width=1088&height=690&format=auto&quality=60`
}

/**
 * 
 * @param {string} id 
 * @returns {string}
 */
export function getTourHomeMobile(id) {
  return `${getDirectusAsset(id)}?width=768&height=816&format=auto&quality=60`
}

/**
 * 
 * @param {string} id 
 * @returns {string}
 */
export function getTourHomeDesktop(id) {
  return `${getDirectusAsset(id)}?width=1088&height=690&format=auto&quality=60`
}

/**
 * 
 * @param { string } url 
 * @param { boolean  | undefined | null } includeUTM 
 * @returns string
 */
export function getURL(url, includeUTM) {
  if (typeof url !== 'string') {
    console.error(`param url needs to be a string but got: ${typeof url} with value: ${url}`)
    return '/'
  }

  if (!includeUTM) return url

  return `${url}?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama`
}
