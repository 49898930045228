//src/data/Tours

//add lines start
import { getTourHeaderImage, getTourImage, getURL } from "lib/utils";
//add lines end

// 0 - Biomuseo
const bannerBiomuseo =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/biomuseo/CPA.1068_stopover_microsite_desktop_tours_masthead_biomuseo_1920x730.jpg";
const gallery1Biomuseo =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/biomuseo/CPA.1068_stopover_microsite_desktop_tours_galeria_01_biomuseo_1088x690.jpg";
const gallery2Biomuseo =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/biomuseo/CPA.1068_stopover_microsite_desktop_tours_galeria_02_biomuseo_1088x690.jpg";
const gallery3Biomuseo =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/biomuseo/CPA.1068_stopover_microsite_desktop_tours_galeria_03_biomuseo_1088x690.jpg";

// 1 - Canopy Tower
const bannerCanopyTower =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/canopy-tower/CPA.1068_stopover_microsite_desktop_tours_masthead_canopytower_1920x730_spa.jpg";
const gallery1CanopyTower =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/canopy-tower/CPA.1068_stopover_microsite_desktop_tours_galeria_01_canopytower_1088x690_spa.jpg";
const gallery2CanopyTower =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/canopy-tower/CPA.1068_stopover_microsite_desktop_tours_galeria_02_canopytower_1088x690_spa.jpg";
const gallery3CanopyTower =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/canopy-tower/CPA.1068_stopover_microsite_desktop_tours_galeria_03_canopytower_1088x690_spa.jpg";
const gallery4CanopyTower =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/canopy-tower/CPA.1068_stopover_microsite_desktop_tours_galeria_04_canopytower_1088x690_spa.jpg";
const gallery5CanopyTower =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/canopy-tower/CPA.1068_stopover_microsite_desktop_tours_galeria_05_canopytower_1088x690_spa.jpg";

// 2 - Canopy Tower
const bannerMuseoContemporaneo =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/museo-de-arte-contemporaneo/CPA.1068_stopover_microsite_desktop_tours_masthead_mac_1920x730_spa.jpg";
const gallery1MuseoContemporaneo =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/museo-de-arte-contemporaneo/CPA.1068_stopover_microsite_desktop_tours_carousel_02_mac_1088x690.jpg";
const gallery2MuseoContemporaneo =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/museo-de-arte-contemporaneo/CPA.1068_stopover_microsite_desktop_tours_galeria_02_mac_1088x690_spa.jpg";
const gallery3MuseoContemporaneo =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/museo-de-arte-contemporaneo/CPA.1068_stopover_microsite_desktop_tours_galeria_03_mac_1088x690_spa.jpg";
const gallery4MuseoContemporaneo =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/museo-de-arte-contemporaneo/CPA.1068_stopover_microsite_desktop_tours_galeria_04_mac_1088x690_spa.jpg";

// 3 - Panamá Helicopter Tour
const bannerHelicopter =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-helicopter-tours/CPA.1068_stopover_microsite_desktop_tours_masthead_04_helicopter_1920x730.jpg";
const gallery1Helicopter =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-helicopter-tours/CPA.1068_stopover_microsite_desktop_tours_galeria_01_helicopter_1088x690.jpg";
const gallery2Helicopter =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-helicopter-tours/CPA.1068_stopover_microsite_desktop_tours_galeria_02_helicopter_1088x690.jpg";
const gallery3Helicopter =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-helicopter-tours/CPA.1068_stopover_microsite_desktop_tours_galeria_03_helicopter_1088x690.jpg";
const gallery4Helicopter =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-helicopter-tours/CPA.1068_stopover_microsite_desktop_tours_galeria_04_helicopter_1088x690.jpg";
const gallery5Helicopter =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-helicopter-tours/CPA.1068_stopover_microsite_desktop_tours_galeria_05_helicopter_1088x690.jpg";
const card1Helicopter =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-helicopter-tours/CPA.1068_stopover_microsite_desktop_tours_card_vertical_01_helicopter_398x236.jpg";
const card2Helicopter =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-helicopter-tours/CPA.1068_stopover_microsite_desktop_tours_card_vertical_02_helicopter_398x236.jpg";
// const card3Helicopter
//   = "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-helicopter-tours/CPA.1068_stopover_microsite_desktop_tours_card_vertical_03_helicopter_398x236.jpg";

// 4 - Panamá Rainforest Discovery Center
const bannerRainforest =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-rainforest-discovery-center/CPA.1068_stopover_microsite_desktop_tours_masthead_rainforest_1920x730.jpg";
const gallery1Rainforest =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-rainforest-discovery-center/CPA.1068_stopover_microsite_desktop_tours_galeria_01_rainforest_1088x690.jpg";
const gallery2Rainforest =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-rainforest-discovery-center/CPA.1068_stopover_microsite_desktop_tours_galeria_02_rainforest_1088x690.jpg";
const gallery3Rainforest =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-rainforest-discovery-center/CPA.1068_stopover_microsite_desktop_tours_galeria_03_rainforest_1088x690.jpg";

// 5 - Panama Sailing Tours
const bannerPanamaSailingTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-sailing-tours/CPA.1286_stopover_microsite_desktop_tours_masthead_panamasailing_1920x730_spa.png";
const gallery1PanamaSailingTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-sailing-tours/CPA.1286_stopover_microsite_desktop_tours_galeria_01_panamasailing_1088x690_spa.png";
const gallery2PanamaSailingTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-sailing-tours/CPA.1286_stopover_microsite_desktop_tours_galeria_02_panamasailing_1088x690_spa.png";
const gallery3PanamaSailingTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panama-sailing-tours/CPA.1286_stopover_microsite_desktop_tours_galeria_03_panamasailing_1088x690_spa.png";

// 6 - Allegro Tours
const bannerAllegroTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/allegro-tours/CPA.1286_stopover_microsite_desktop_tours_masthead_allegrotpurs_1920x730_spa.png";
const gallery1AllegroTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/allegro-tours/CPA.1286_stopover_microsite_desktop_tours_galeria_01_allegrotpurs_1088x690_spa.png";
const gallery2AllegroTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/allegro-tours/CPA.1286_stopover_microsite_desktop_tours_galeria_02_allegrotpurs_1088x690_spa.png";
const gallery3AllegroTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/allegro-tours/CPA.1286_stopover_microsite_desktop_tours_galeria_03_allegrotpurs_1088x690_spa.png";
const gallery4AllegroTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/allegro-tours/CPA.1286_stopover_microsite_desktop_tours_galeria_04_allegrotpurs_1088x690_spa.png";
const card1AllegroTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/allegro-tours/CPA.1286_stopover_microsite_desktop_tours_galeria_02_allegrotpurs_1088x690_spa.png";
const card2AllegroTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/allegro-tours/CPA.1286_stopover_microsite_desktop_tours_galeria_03_allegrotpurs_1088x690_spa.png";

// 7 - RGE Travel
const bannerRGETravel =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/rge-travel/CPA.1286_stopover_microsite_desktop_tours_masthead_rge_1920x730_spa.png";
const gallery1RGETravel =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/rge-travel/CPA.1286_stopover_microsite_desktop_tours_galeria_01_rge_1088x690_spa.png";
const gallery2RGETravel =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/rge-travel/CPA.1286_stopover_microsite_desktop_tours_galeria_02_rge_1088x690_spa.png";
const gallery3RGETravel =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/rge-travel/CPA.1286_stopover_microsite_desktop_tours_galeria_03_rge_1088x690_spa.png";
const gallery4RGETravel =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/rge-travel/CPA.1286_stopover_microsite_desktop_tours_galeria_04_rge_1088x690_spa.png";
const card1RGETravel =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/rge-travel/CPA.1286_stopover_microsite_desktop_tours_galeria_01_rge_1088x690_spa.png";
const card2RGETravel =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/rge-travel/CPA.1286_stopover_microsite_desktop_tours_galeria_04_rge_1088x690_spa.png";

// 8 - Monkey Adventure
const bannerMonkeyAdventure =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/monkey-adventures/CPA.1286_stopover_microsite_desktop_monkey_adventure_masthead_1920x730.png";
const gallery1MonkeyAdventure =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/monkey-adventures/CPA.1286_stopover_microsite_desktop_monkey_adventure_gallery_01_1088x690.png";
const gallery2MonkeyAdventure =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/monkey-adventures/CPA.1286_stopover_microsite_desktop_monkey_adventure_gallery_02_1088x690.png";
const gallery3MonkeyAdventure =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/monkey-adventures/CPA.1286_stopover_microsite_desktop_monkey_adventure_gallery_03_1088x690.png";

// 9 - San Blas Dreams
const bannerSanBlasDreams =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/san-blas-dreams/CPA.1286_stopover_microsite_desktop_san_blas_dreams_masthead_1920x730.png";
const gallery1SanBlasDreams =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/san-blas-dreams/CPA.1286_stopover_microsite_desktop_san_blas_dreams_gallery_01_1088x690.png";
const gallery2SanBlasDreams =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/san-blas-dreams/CPA.1286_stopover_microsite_desktop_san_blas_dreams_gallery_02_1088x690.png";
const gallery3SanBlasDreams =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/san-blas-dreams/CPA.1286_stopover_microsite_desktop_san_blas_dreams_gallery_03_1088x690.png";
const card1SanBlasDreams =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/san-blas-dreams/CPA.1286_stopover_microsite_desktop_muellecito_tarjeta_1088x690.png";
const card2SanBlasDreams =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/san-blas-dreams/CPA.1286_stopover_microsite_desktop_embera_Tarjetas_1088x690.png";

// 10 - Parque Metropolitano
const bannerParqueMetropolitano =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/parque-metropolitano/CPA.1286_stopover_microsite_desktop_parque_metropolitano_masthead_1920x730.png";
const gallery1ParqueMetropolitano =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/parque-metropolitano/CPA.1286_stopover_microsite_desktop_parque_metropolitano_gallery_01_1088x690.png";
const gallery2ParqueMetropolitano =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/parque-metropolitano/CPA.1286_stopover_microsite_desktop_parque_metropolitano_gallery_02_1088x690.png";
const gallery3ParqueMetropolitano =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/parque-metropolitano/CPA.1286_stopover_microsite_desktop_parque_metropolitano_gallery_03_1088x690.png";

// Remove lines start
// 11 - Ancon Expeditions
/* const bannerAnconExpeditions =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ancon-expeditions/CPA.1286_stopover_microsite_desktop_ancon_expedition_masthead_1920x730.png";
const gallery1AnconExpeditions =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ancon-expeditions/CPA.1286_stopover_microsite_desktop_ancon_expedition_gallery_01_1088x690.png";
const gallery2AnconExpeditions =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ancon-expeditions/CPA.1286_stopover_microsite_desktop_ancon_expedition_gallery_02_1088x690.png";
const gallery3AnconExpeditions =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ancon-expeditions/CPA.1286_stopover_microsite_desktop_ancon_expedition_gallery_03_1088x690.png";
const card1AnconExpeditions =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ancon-expeditions/CPA.1286_stopover_microsite_desktop_canal_01_tarjeta_1088x690.png";
const card2AnconExpeditions =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ancon-expeditions/CPA.1286_stopover_microsite_desktop_expedicion_bote_01_tarjeta_1088x690.png"; */
// Removes line end

// 12 - Hover Tours
const bannerHoverTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/hover-tours/CPA.1286_stopover_microsite_desktop_hover_tour_masthead_1920x730.png";
const gallery1HoverTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/hover-tours/CPA.1286_stopover_microsite_desktop_hover_tour_gallery_01_1088x690.png";
const gallery2HoverTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/hover-tours/CPA.1286_stopover_microsite_desktop_hover_tour_gallery_02_1088x690.png";
const gallery3HoverTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/hover-tours/CPA.1286_stopover_microsite_desktop_hover_tour_gallery_03_1088x690.png";
const card1HoverTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/hover-tours/CPA.1286_stopover_microsite_desktop_3ciudades_tarjeta_1088x690.png";
const card2HoverTours =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/hover-tours/CPA.1286_stopover_microsite_desktop_enigmas_tarjeta_1088x690.png";

// 14 - Voyair PTY
const bannerVoyairPTY =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/voyair-pty/CPA.1286_stopover_microsite_desktop_voyair_masthead_1920x730.png";
const gallery1VoyairPTY =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/voyair-pty/CPA.1286_stopover_microsite_desktop_voyair_gallery_01_1088x690.png";
const gallery2VoyairPTY =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/voyair-pty/CPA.1286_stopover_microsite_desktop_voyair_gallery_02_1088x690.png";
const gallery3VoyairPTY =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/voyair-pty/CPA.1286_stopover_microsite_desktop_voyair_gallery_03_1088x690.png";
const card1VoyairPTY =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/voyair-pty/CPA.1286_stopover_microsite_desktop_senderismo_tarjeta_1088x690.png";
const card2VoyairPTY =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/voyair-pty/CPA.1286_stopover_microsite_desktop_pasep_caballo_tarjeta_1088x690.png";

// 15 - Trip de Jenny
const bannerTripJenny =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/trip-de-jenny/CPA.1286_stopover_microsite_desktop_trip_de_jenny_masthead_1920x730.png";
const gallery1TripJenny =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/trip-de-jenny/CPA.1286_stopover_microsite_desktop_trip_de_jenny_gallery_01_1088x690.png";
const gallery2TripJenny =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/trip-de-jenny/CPA.1286_stopover_microsite_desktop_trip_de_jenny_gallery_02_1088x690.png";
const gallery3TripJenny =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/trip-de-jenny/CPA.1286_stopover_microsite_desktop_trip_de_jenny_gallery_03_1088x690.png";
const card1TripJenny =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/trip-de-jenny/CPA.1286_stopover_microsite_desktop_caribel_01_tarjeta_1088x690.png";
const card2TripJenny =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/trip-de-jenny/CPA.1286_stopover_microsite_desktop_colon_tarjeta_1088x690.png";

// 16 - Tree Trek Gamboa
const bannerTreeTrekGamboa =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/tree-trek-gamboa/CPA.1286_stopover_microsite_desktop_tree_trek_gamboa_masthead_1920x730.png";
const gallery1TreeTrekGamboa =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/tree-trek-gamboa/CPA.1286_stopover_microsite_desktop_tree_trek_gamboa_gallery_01_1088x690.png";
const gallery2TreeTrekGamboa =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/tree-trek-gamboa/CPA.1286_stopover_microsite_desktop_tree_trek_gamboa_gallery_02_1088x690.png";
const gallery3TreeTrekGamboa =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/tree-trek-gamboa/CPA.1286_stopover_microsite_desktop_tree_trek_gamboa_gallery_03_1088x690.png";

// 17 - Ecocircuitos
const bannerEcocircuitos =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ecocircuitos/CPA.1286_stopover_microsite_desktop_ecocircuitos_cayak_02_1088x690.png";
const gallery1Ecocircuitos =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ecocircuitos/CPA.1286_stopover_microsite_desktop_ecocircuitos_gallery_01_1088x690.png";
const gallery2Ecocircuitos =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ecocircuitos/CPA.1286_stopover_microsite_desktop_ecocircuitos_gallery_02_1088x690.png";
const gallery3Ecocircuitos =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ecocircuitos/CPA.1286_stopover_microsite_desktop_ecocircuitos_gallery_03_1088x690.png";
const card1Ecocircuitos =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ecocircuitos/CPA.1286_stopover_microsite_desktop_ecocircuitos_canal_01_tarjeta_1088x690.png";
const card2Ecocircuitos =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/ecocircuitos/CPA.1286_stopover_microsite_desktop_ecocircuitos_cayak_02_1088x690.png";

const bannerScubaPanama =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/scubapanama/CPA.1420_stopover_microsite_desktop_tours_masthead_scubapanama_1920x730.png";
const gallery1ScubaPanama =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/scubapanama/CPA.1420_stopover_microsite_desktop_tours_galeria_01_scubapanama_1088x690.png";
const gallery2ScubaPanama =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/scubapanama/CPA.1420_stopover_microsite_desktop_tours_galeria_02_scubapanama_1088x690.png";
const gallery3ScubaPanama =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/scubapanama/CPA.1420_stopover_microsite_desktop_tours_galeria_03_scubapanama_1088x690.png";
const card1ScubaPanama =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/scubapanama/scuba-buzos.png";
const card2ScubaPanama =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/scubapanama/scuba-pez.png";

// Remove lines start
/* const bannerPanamaNatural =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panamanatural/CPA.1068_stopover_microsite_desktop_tours_masthead_panamaalnatural_1920x730.png";
const gallery1PanamaNatural =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panamanatural/CPA.1420stopover_microsite_desktop_tours_galeria_01_panamaalnatural_1088x690.png";
const gallery2PanamaNatural =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panamanatural/CPA.1420_stopover_microsite_desktop_tours_galeria_02_panamaalnatural_1088x690.png";
const gallery3PanamaNatural =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panamanatural/CPA.1420_stopover_microsite_desktop_tours_galeria_03_panamaalnatural_1088x690.png";
const gallery4PanamaNatural =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panamanatural/CPA.1420_stopover_microsite_desktop_tours_galeria_04_panamaalnatural_1088x690.png";
const gallery5PanamaNatural =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panamanatural/CPA.1420_stopover_microsite_desktop_tours_galeria_05_panamaalnatural_1088x690_.png";
const card1PanamaNatural =
  "https://phpstack-685265-3053015.cloudwaysapps.com/storage/img/tours/panamanatural/panamanatural-card.png"; */

// Remove lines end

const museoDeLaMola = {
  banner:
    "https://cm-marketing.directus.app/assets/c1dcfa84-72b0-4735-bffb-886b1048b3eb?width=1920&height=730&format=auto&quality=60",
  gallery: [
    "https://cm-marketing.directus.app/assets/c1dcfa84-72b0-4735-bffb-886b1048b3eb?width=1088&height=690&format=auto&quality=60",
    "https://cm-marketing.directus.app/assets/c84f83ea-f789-4d4b-b176-3d6c3bb8f939?width=1088&height=690&format=auto&quality=60",
    "https://cm-marketing.directus.app/assets/e6244ac0-9a0c-497c-b373-7022a252e974?width=1088&height=690&format=auto&quality=60",
    "https://cm-marketing.directus.app/assets/816475d0-42ec-44e3-9ae8-6407e642be5e?width=1088&height=690&format=auto&quality=60",
    "https://cm-marketing.directus.app/assets/89e7d2f8-fa0c-4cd4-95dd-d1d2b24eab11?width=1088&height=690&format=auto&quality=60",
  ],
};

const poinPanama = {
  banner:
    "https://cm-marketing.directus.app/assets/2b38246a-350e-48ec-bc84-f4917689bff8?width=1920&height=730&format=auto&quality=60",
  gallery: [
    "https://cm-marketing.directus.app/assets/b382ea02-68c2-4d23-a1b7-9c1dfd3c5b7b?width=1088&height=690&format=auto&quality=60",
    "https://cm-marketing.directus.app/assets/80a4d50b-3f08-4bda-a495-a27364359bb7?width=1088&height=690&format=auto&quality=60",
    "https://cm-marketing.directus.app/assets/89890c16-8b65-4152-8d7c-2524cb7fa5c9?width=1088&height=690&format=auto&quality=60",
    "https://cm-marketing.directus.app/assets/f9aff9ba-3cc3-4c53-a837-82480ff1effe?width=1088&height=690&format=auto&quality=60",
  ],
  tour: {
    border:
      "https://cm-marketing.directus.app/assets/d4b0339c-6925-4ae1-a11c-2632f5244b49?width=1088&height=690&format=auto&quality=60",
    swing:
      "https://cm-marketing.directus.app/assets/947584e5-5afc-410c-a50d-7d3a30083908?width=1088&height=690&format=auto&quality=60",
    viewpoint:
      "https://cm-marketing.directus.app/assets/cd96bc9c-39e4-4e7b-97e8-cff3194827d6?width=1088&height=690&format=auto&quality=60",
    package:
      "https://cm-marketing.directus.app/assets/bf63a3b7-eae9-473b-83e0-5a8ad489a044?width=1088&height=690&format=auto&quality=60",
    zipline:
      "https://cm-marketing.directus.app/assets/2e127231-28fb-425f-ab8f-582ac3eab00e?width=1088&height=690&format=auto&quality=60",
  },
};

//add lines start
const GoPanamaBikeTours = {
  banner: getTourHeaderImage('2af7b591-3ca2-4a32-aed1-018c3ad9b171'),
  gallery: [
    '4c6a212c-e593-4dd7-9f68-3b6500d5e429', 
    'ed3616f4-869f-4b6c-83af-44b03ed2be07', 
    '4208733d-15a4-42f7-9809-0bc464dba7e6', 
    '2af7b591-3ca2-4a32-aed1-018c3ad9b171', 
    'aa1aa1e9-f13d-4484-83c4-509127a364dc',
    '297cf1fe-9c0c-4db3-a528-3c348ad2b49d',
    'a6a14d06-d4e3-4c14-96cc-991d7e79f5ab'
  ].map(id => getTourImage(id)),
  tour: {
    panamaNights: getTourImage('2af7b591-3ca2-4a32-aed1-018c3ad9b171'),
    casco: getTourImage('297cf1fe-9c0c-4db3-a528-3c348ad2b49d'),
  }
}

const AnconExpeditions = {
  banner: getTourHeaderImage('1e754671-dccb-47a6-a9bd-8925744a3b00'),
  gallery: [
    '3efb69e4-e666-4511-8ba4-3f3ca64249f0', 
    '3370586e-5be6-485b-9756-b87d00fe84c8', 
    'ae0302cc-dce5-4483-a317-d4590ca16bc5'
  ].map(id => getTourImage(id)),
  tour: {
    canal: getTourImage('3efb69e4-e666-4511-8ba4-3f3ca64249f0'),
    selva: getTourImage('ae0302cc-dce5-4483-a317-d4590ca16bc5'),
  }
}

const PanamaAlNatural = {
  banner: getTourHeaderImage('eceb251e-3ed0-4dcd-a2dc-1098c992d113'),
  gallery: [
    'b970fb65-67bf-4352-a170-2629138c862c', 
    '1902ee88-5ed4-4445-8c37-e7bcbcc13110',
    '9eed7501-3c87-4a6d-998a-3d9b09ee171a',
    'bbd64181-5f10-423a-a518-87a89b8ef4dc',
    '22dbc3f4-340b-4009-be06-7c8aae6698c5'
  ].map(id => getTourImage(id)),
  tour: {
    canal: getTourImage('b6d05349-e346-45f5-afba-93e04f89bbcd'),
    ciudad: getTourImage('c8d7beb6-3404-4a88-831c-edf858f19661'),
  }
}

const OurPanama = {
  banner: getTourHeaderImage('04620b7c-6034-4efe-933c-20efe5f459b2'),
  gallery: [
    'fc3f33ee-b2a7-45c2-8f08-7a19db0618df', 
    '77f5b395-322b-4924-bd82-f023b6e4fdbb',
    'defd259f-3b85-4176-8ec4-7d2d3162b033',
    '57e45626-c988-46e2-9978-a7be4a826b7b',
    '042efa58-2742-4bd5-82a3-e033ad127f4c'
  ].map(id => getTourImage(id)),
  tour: {
    venas: getTourImage('abbc8330-dc4d-4488-97f6-b7d49b4bfb36'),
    guna: getTourImage('ca9899b4-0277-4f52-8216-bc518d8dc942'),
  }
}

//add lines end

const data = {
  es: {
    name: "Tours",
    tours: [
      {
        id: 0,
        slug: "biomuseo",
        bannerImg: bannerBiomuseo,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Biomuseo",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Biomuseo",
            subtitle: "Biomuseo.",
            thirdtitle: "",
            infoUrl:
              "https://biomuseo.org",
          },
        ],
        extraInfo: [
          {
            description:
              "Diseñado por el mundialmente reconocido arquitecto Frank Gehry, el Biomuseo es su primera obra en Latinoamérica y en el trópico. El edificio fue diseñado para contar la historia de cómo el istmo de Panamá surgió del mar, uniendo dos continentes, separando un gran océano en dos y cambiando la biodiversidad del planeta para siempre. Además de los espacios principales, el museo incluye un atrio público, un espacio para exhibiciones temporales, una tienda, una cafetería y múltiples exhibiciones exteriores dispuestas en un parque botánico.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "<strong>USD 15.00</strong> (adultos) tarifa especial para clientes Stopover",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.932401471861311,
            lng: -79.54458165917156,
          },
        ],
        gallery: [gallery1Biomuseo, gallery2Biomuseo, gallery3Biomuseo],
        infoUrl:
          "https://biomuseo.org",
      },
      {
        id: 1,
        slug: "canopy-tower",
        bannerImg: bannerCanopyTower,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Canopy Tower",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Canopy Tower",
            subtitle: "Canopy Tower.",
            thirdtitle: "",
            infoUrl:
              "https://www.canopytower.com/canopy-tower/things-to-do/day-visits?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "15% de descuento en visitas de medio dia. Todos nuestros recorridos de observación de aves están dirigidos por guías bilingües y expertos en observación de aves/naturaleza que llevan binoculares Leica y un visor Leica. Nuestros grupos son un máximo de 8 observadores de aves y un guía. Nuestras excursiones de un día en Panamá incluyen una comida deliciosa, entrada al Parque Nacional Soberanía y el uso de la plataforma de observación de la Torre Canopy, donde nuestros guías lo ayudarán a identificar la vida silvestre.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "Tenga en cuenta que nuestros tours de un día en Panamá están sujetos a la disponibilidad de guías y espacio y haremos todo lo posible para acomodar a los visitantes externos a la Canopy Tower; sin embargo, se da prioridad a los espacios en los recorridos de vida silvestre para alojar a los huéspedes. Los precios son por persona y no incluyen el 7% de ITBMS",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 9.077799693777974,
            lng: -79.64920018985872,
          },
        ],
        gallery: [
          gallery1CanopyTower,
          gallery2CanopyTower,
          gallery3CanopyTower,
          gallery4CanopyTower,
          gallery5CanopyTower,
        ],
        //Remove lines start
/*         gallery: [
          gallery1CanopyTower,
          gallery2CanopyTower,
          gallery3CanopyTower,
          gallery4CanopyTower,
          gallery5CanopyTower,
        ], */
        //Remove lines end
        infoUrl:
          "https://www.canopytower.com/canopy-tower/things-to-do/day-visits?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 2,
        slug: "museo-de-arte-contemporaneo",
        bannerImg: bannerMuseoContemporaneo,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Museo de Arte Contemporáneo",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Museo de Arte Contemporáneo",
            subtitle: "Museo de Arte Contemporáneo.",
            thirdtitle: "",
            infoUrl:
              "https://macpanama.org/products/panama-stopover-kit?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "Vive una experiencia única en el MAC PANAMÁ con un visita guiada personalizada, snacks de bienvenida y kit de recuerdo con regalo del museo. Recorre nuestra exposición con los guías del museo para que conozcas en profundidad el concepto de las obras, la historia de sus artistas, preguntas claves de reflexión y los detalles de la historia del único museo de arte del país.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "Las visitas podrán hacerse de viernes a domingo de 10 am a 5 pm. Los pasajeros deberán reservar su día y hora de visita enviando un email a info@macpanama.org. Esta promoción será válida solo para pasajeros de Stopover Copa Airlines. Los pasajeros deberán mostrar en recepción su recibo de pago online y su pasaje de Stopover",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.960866490996098,
            lng: -79.54304734923971,
          },
        ],
        gallery: [
          gallery1MuseoContemporaneo,
          gallery2MuseoContemporaneo,
          gallery3MuseoContemporaneo,
          gallery4MuseoContemporaneo,
        ],
        infoUrl:
          "https://macpanama.org/products/panama-stopover-kit?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 3,
        slug: "panama-helicopter-tours",
        bannerImg: bannerHelicopter,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Panama Helicopter Tours",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Panama Helicopter Tours",
            subTitle: "",
            thirdTitle: "",
            infoUrl:
              "https://panamahelicoptertours.com/#!/tours?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "Los tours están disponibles de lunes a domingo de 7:00am a 5:00pm. Los tours podrán ser compartidos con otros pasajeros. Considerar que el tiempo de vuelo puede variar acorde a circunstancias particulares; el tiempo promedio de vuelo es entre 5 a 9 minutos. Los pasajeros deben pesar 250lbs o menos.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1Helicopter,
            title: "Panamá Helicopter Tour",
            subtitle:
              "El paquete 1, Helicopter City Tour, incluye lo siguiente:",
            items: [
              {
                text: "Salida y retorno del aeropuerto de Albrook.",
                feePerPerson: "",
              },
              {
                text: "Sobrevuelo sobre la Ciudad de Panamá.",
                feePerPerson: "",
              },
              {
                text: "Vistas aéreas de  atracciones tales como Panamá Viejo, Balboa, Costa del Este, Causeway, Casco Antiguo, la entrada del Canal de Panamá y otros.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duración: 30 minutos."],
            discount: "Mínimo 3 personas",
            infoUrl:
              "https://panamahelicoptertours.com/#!/tours/city-pacific-canal-tour-pacific-side?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2Helicopter,
            title: "Helicopter Ocean 2 Ocean",
            subtitle:
              "El paquete 2, Helicopter Ocean 2 Ocean, incluye lo siguiente:",
            items: [
              {
                text: "Salida del aeropuerto de Albrook.",
                feePerPerson: "",
              },
              {
                text: "Sobrevuelo por la Ciudad de Panamá y el Canal de Panamá.",
                feePerPerson: "",
              },
              {
                text: "Vistas aereas de atracciones como el Canal de Panamá, el Puerto de Colón, el Fuerte San Lorenzo, Río Chagres, Costa del Este, Balboa, Marina, Yacht Club, Causeway, Casco Antiguo y más.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duración: 1 hora."],
            discount: "Mínimo 3 personas",
            infoUrl:
              "https://panamahelicoptertours.com/#!/tours/city-canal-tour-from-pacific-to-atlantic-side?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 8.968293347089691,
            lng: -79.55837985953471,
          },
        ],
        gallery: [
          gallery1Helicopter,
          gallery2Helicopter,
          gallery3Helicopter,
          gallery4Helicopter,
          gallery5Helicopter,
        ],
        //Remove lines start
/*         gallery: [
          gallery1Helicopter,
          gallery2Helicopter,
          gallery3Helicopter,
          gallery4Helicopter,
          gallery5Helicopter,
        ], */
        //Remove lines end
        infoUrl:
          "https://panamahelicoptertours.com/#!/tours?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 4,
        slug: "panama-rainforest-discovery-center",
        bannerImg: bannerRainforest,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Panamá Rainforest Discovery Center",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Panamá Rainforest Discovery Center",
            subtitle: "Panamá Rainforest Discovery Center.",
            thirdtitle: "",
            infoUrl:
              "https://pipelineroad.org/en/stop-over?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "Un centro interpretativo del bosque tropical a 20 minutos de la Ciudad de Panamá. Promueve la educación ambiental y el turismo sostenible.",
            open: "Abierto los viernes, sábado y domingo. de 6:00am a 4:00pm.",
            social: [
              {
                name: "Instagram",
                url: "https://www.instagram.com/panama_rainforest_dc",
                text: "@panama_rainforest_dc.",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 9.13377853509827,
            lng: -79.72066697636642,
          },
        ],
        gallery: [gallery1Rainforest, gallery2Rainforest, gallery3Rainforest],
        infoUrl:
          "https://pipelineroad.org/en/stop-over?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 5,
        slug: "panama-sailing-tours",
        bannerImg: bannerPanamaSailingTours,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Panama Sailing Tours",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Panama Sailing Tours",
            subtitle: "Panama Sailing Tours.",
            thirdtitle: "",
            infoUrl:
              "http://panamasailingtours.com/cat_ing/pasadia-taboga-todo-incluido-2/?lang=es?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "Tour en Catamaran Todo Incluido a Isla Taboga",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "Esta es la excusa perfecta para tener un día inolvidable. Con nuestro plan todo incluido podrás pasar el día en una de las Islas más cercanas a la ciudad. Tanto niños como adultos se divertirán!. Incluye: Open Bar, Snacks, Almuerzo, Animación, Visita a la parte trasera de la Isla, Visita a la playa.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.94196752051534,
            lng: -79.55561528303423,
          },
        ],
        gallery: [
          gallery1PanamaSailingTours,
          gallery2PanamaSailingTours,
          gallery3PanamaSailingTours,
        ],
        //Remove lines start
/*         gallery: [
          gallery1PanamaSailingTours,
          gallery2PanamaSailingTours,
          gallery3PanamaSailingTours,
        ], */
        //Remove lines end
        infoUrl:
          "http://panamasailingtours.com/cat_ing/pasadia-taboga-todo-incluido-2/?lang=es?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 6,
        slug: "allegro-tours",
        bannerImg: bannerAllegroTours,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Allegro Tours",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Allegro Tours",
            subTitle: "",
            thirdTitle: "",
            infoUrl:
              "https://allegrotours.net/experiences/detail/60a688126729e7d89b6577ea?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1AllegroTours,
            title: "Día en la Ciudad de Panamá & Centros comerciales",
            subtitle: "El paquete incluye lo siguiente:",
            items: [
              {
                text: "Te invitamos a un recorrido por los lugares más emblemáticos de la Ciudad de Panamá y una parada de medio día de compras en uno de los principales Malls con mayor atractivo turístico Albrook Mall o Multiplaza Mall.",
                feePerPerson: "",
              },
              {
                text: "Traslado ida y vuelta al aeropuerto.",
                feePerPerson: "",
              },
              {
                text: "Visita por la ciudad asistida.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [],
            discount: "",
            infoUrl:
              "https://allegrotours.net/experiences/detail/60a688126729e7d89b6577ea?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2AllegroTours,
            title: "PANAMA EXPRESS",
            subtitle: "El paquete incluye lo siguiente:",
            items: [
              {
                text: "Te invitamos a que conozcas la maravillosa Ciudad de Panamá.",
                feePerPerson: "",
              },
              {
                text: "Un recorrido por: Panamá la Vieja, visita panorámica por el Biomuseo, la Calzada de Amador y Casco Antiguo.",
                feePerPerson: "",
              },
              {
                text: "Traslado ida y vuelta al aeropuerto.",
                feePerPerson: "",
              },
              {
                text: "Visita por la ciudad asistida. ",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl:
              "https://allegrotours.net/experiences/detail/60a687276729e7d89b6577e9?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 8.98383207302121,
            lng: -79.52322448465601,
          },
        ],
        gallery: [
          gallery1AllegroTours,
          gallery2AllegroTours,
          gallery3AllegroTours,
          gallery4AllegroTours,
        ],
        infoUrl: "",
      },
      {
        id: 7,
        slug: "rge-travel",
        bannerImg: bannerRGETravel,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "RGE Travel",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "RGE Travel",
            subTitle: "",
            thirdTitle: "",
            infoUrl:
              "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1RGETravel,
            title: "Tour de la ciudad & monos",
            subtitle: "El paquete incluye lo siguiente:",
            items: [
              {
                text: "Iniciamos  con el Casco Antiguo, el cual ofrece una sensación única de tradición como de transición.",
                feePerPerson: "",
              },
              {
                text: "Viejos ediﬁcios de tres y cuatro pisos, con techos de teja roja y rejas de hierro forjado, alrededor de balcones adornados con macetas de plantas, rodean plazas históricas y ruinas impresionantes  continuamos con  nuestros  recorrido por El distrito ﬁnanciero cual se encuentra en el corazón de la Ciudad de Panamá hasta llegar  a gamboa  donde  tomaremos una lancha  para Vivir una experiencia nueva y aventúrate a navegar por el canal de Panama, viajaremos por el lago Gatún hasta llegar a la famosa isla de los monos donde interactuáremos con diferentes tipos de monos y podrás disfrutar de bellos paisajes en medio de la jungla.",
                feePerPerson: "",
              },
              {
                text: "Guías especializados.",
                feePerPerson: "",
              },
              {
                text: "Salida a las 7:00 am / retorno a las 13:00 pm.",
                feePerPerson: "",
              },
              {
                text: "Servicio  compartido.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl:
              "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2RGETravel,
            title: "Tour Ciudad + Gira de Monos + Emberá",
            subtitle: "El paquete incluye lo siguiente:",
            items: [
              {
                text: "Punto de Encuentro Lobby del hotel.",
                feePerPerson: "",
              },
              {
                text: "Snack, lancha.",
                feePerPerson: "",
              },
              {
                text: "Descriptivo: Iniciamos  con el Casco Antiguo, el cual ofrece una sensación única de tradición como de transición. Viejos ediﬁcios de tres y cuatro pisos, con techos de teja roja y rejas de hierro forjado, alrededor de balcones adornados con macetas de plantas, rodean plazas históricas y ruinas impresionantes  continuamos con  nuestros  recorrido por El distrito ﬁnanciero cual se encuentra en el corazón de la Ciudad de Panamá hasta llegar  a gamboa  donde  tomaremos una lancha  donde Vivirás  una experiencia nueva y aventúrate a navegar por el canal de Panama, viajaremos por el lago Gatún hasta llegar a la famosa isla de los monos donde interactuáremos con diferentes tipos de monos y podrás disfrutar de bellos paisajes en medio de la jungla luego Posteriormente nos dirigiremos a Embera, un pequeño pueblo tradicional ubicado en la ribera del río Chagres en medio de la selva tropical. Los Emberas mostrarán sus tradiciones, su forma de vestir y cómo elaboran sus artesanías. Realizarán sus bailes y música tradicionales y tendremos tiempo para interactuar directamente con ellos. Disfrutaremos del almuerzo preparado por la comunidad Embera Guías especializados ",
                feePerPerson: "",
              },
              {
                text: "Salida a las  7:00 am / retorno a las 14:00 pm.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl:
              "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 9.070385285702272,
            lng: -79.4506624,
          },
        ],
        gallery: [
          gallery1RGETravel,
          gallery2RGETravel,
          gallery3RGETravel,
          gallery4RGETravel,
        ],
        infoUrl:
          "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 8,
        slug: "monkey-adventures",
        bannerImg: bannerMonkeyAdventure,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Monkey Adventures",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Monkey Adventures",
            subtitle: "Monkey Adventures.",
            thirdtitle: "",
            infoUrl:
              "https://www.monkeyadventures.com.pa/copia-de-isla-grande-tour?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "Tour que combina la Isla de los Monos y el Santuario de los Perezosos en la Selva de Panamá.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 9.075203527292032,
            lng: -79.65918303018104,
          },
        ],
        gallery: [
          gallery1MonkeyAdventure,
          gallery2MonkeyAdventure,
          gallery3MonkeyAdventure,
        ],
        infoUrl:
          "https://www.monkeyadventures.com.pa/copia-de-isla-grande-tour?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 9,
        slug: "san-blas-dreams",
        bannerImg: bannerSanBlasDreams,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "San Blas Dreams",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "San Blas Dreams",
            subTitle: "",
            thirdTitle: "",
            infoUrl:
              "https://www.sanblasdreams.com/panama-stopover1.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1SanBlasDreams,
            title: "San Blas",
            subtitle: "Este tour incluye:",
            items: [
              {
                text: "Transporte compartido ida y vuelta desde su alojamiento en la Ciudad de Panamá​ hacia las Islas de San Blas.",
                feePerPerson: "",
              },
              {
                text: "Lancha ida y vuelta desde y hacia el puerto.",
                feePerPerson: "",
              },
              {
                text: "Almuerzo.",
                feePerPerson: "",
              },
              {
                text: "Equipo de Snorkel.",
                feePerPerson: "",
              },
              {
                text: "Tour Isla Yanis y Piscina Natural (debido a las restricciones no es posible visitar otras islas).",
                feePerPerson: "",
              },
              {
                text: " Impuestos de entrada en la Comarca y en el puerto.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duración del tour: 12 horas."],
            discount: "",
            infoUrl:
              "https://www.sanblasdreams.com/panama-stopover1.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2SanBlasDreams,
            title: "Comunidad Emberá ",
            subtitle: "Este tour incluye:",
            items: [
              {
                text: "Transporte del aeropuerto al hotel ida y vuelta hacia la Comunidad de Emberá.",
                feePerPerson: "",
              },
              {
                text: "Tour privado de la Comunidad Emberá, cruzaremos el Río Chagres a bordo de la famosa Piragua.",
                feePerPerson: "",
              },
              {
                text: "Almuerzo tradicional, ensalada de frutas tropicales y refrescos.",
                feePerPerson: "",
              },
              {
                text: "Impuestos.",
                feePerPerson: "",
              },
              {
                text: "Guías de habla hispana e inglesa.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duración del tour: 6 a 7 horas"],
            discount: "Mínimo 2 adultos",
            infoUrl:
              "https://www.sanblasdreams.com/panama-stopover1.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 8.978104863523583,
            lng: -79.52091783150577,
          },
        ],
        gallery: [
          gallery1SanBlasDreams,
          gallery2SanBlasDreams,
          gallery3SanBlasDreams,
        ],
        infoUrl:
          "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 10,
        slug: "parque-metropolitano",
        bannerImg: bannerParqueMetropolitano,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Parque Natural Metropolitano",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Parque Natural Metropolitano",
            subtitle: "Parque Natural Metropolitano.",
            thirdtitle: "",
            infoUrl:
              "https://parquenaturalmetro.wixsite.com/panama2021?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "El Parque Natural Metropolitano forma parte del Sistema Nacional de Áreas Protegidas de Panamá ubicado dentro de los límites de la ciudad ; considerado el “Pulmón de la ciudad  de Panamá” es el único bosque tropical en Centroamérica dentro de una metrópolis, a solo 10 minutos apróximadamente de la ciudad , cuenta con una extensión apróximada 232 hectáreas , albergando una rica biodiversidad de flora y fauna, además entre sus atractivos, cuenta con un total de 5.07 kilómetros (3.2 millas) de senderos, miradores como el mirador Cerro Cedro a 156 msnm, con una vista panorámica de la ciudad, el Canal y otras áreas protegidas  formando un corredor biológico natural.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "CONDICIONES Y RESTRICCIONES</p><p>Debe realizar su reserva previo a su visita en el siguiente link <a href='https://survey123.arcgis.com/share/ae32a001539543e9b07d8fedbd6bcfd3' target='_blank'>https://survey123.arcgis.com/share/ae32a001539543e9b07d8fedbd6bcfd3</a>.</p><p>Cuando visites el Parque recuerda ser un visitante responsable y sigue nuestras recomendaciones:</p><p>1. Siga las señalizaciones.</p><p>2. Manténgase dentro de los senderos.</p><p>3. Evite tocar las plantas.</p><p>4. No se cuelgue de las lianas o bejucos.</p><p>5. Se prohíbe la introducción o extracción de cualquier organismo o plantas.</p><p>6. Prohibido fumar.</p><p>7. No tallar los árboles o rayar las estructuras.</p><p>8. No esta permitido el ingreso de mascotas.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.984870152823333,
            lng: -79.54679697383372,
          },
        ],
        gallery: [
          gallery1ParqueMetropolitano,
          gallery2ParqueMetropolitano,
          gallery3ParqueMetropolitano,
        ],
        infoUrl:
          "https://parquenaturalmetro.wixsite.com/panama2021?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      // Replace item start
      {
        id: 11,
        slug: "ancon-expeditions",
        bannerImg: AnconExpeditions.banner,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Ancon Expeditions",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Ancon Expeditions",
            subTitle: "",
            thirdTitle: "",
            infoUrl: getURL("https://www.anconexpeditions.com/", true),
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: AnconExpeditions.tour.canal,
            title: "La Ciudad y el Canal de Panamá",
            subtitle: "",
            items: [{
              text:'¿Sólo un día en la ciudad? No hay problema. Disfrute de los lugares más destacados a poca distancia del aeropuerto de Tocumen, como Panamá Viejo y el Casco Viejo, declarados Patrimonio de la Humanidad por la UNESCO, el Centro de Visitantes de las Esclusas de Miraflores en el Canal de Panamá, un paseo en coche por la antigua Zona del Canal, el Museo de la Biodiversidad, una ruta en bicicleta por la Calzada de Amador o incluso una clase práctica de cocina con un joven chef panameño.'
            }],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl: getURL('https://anconexpeditions.com/products/panama-city-the-panama-canal', true),
          },
          {
            img: AnconExpeditions.tour.selva,
            title: "La espectacular selva tropical de Panamá",
            subtitle: '',
            items: [{
              text: "A sólo 60 minutos del aeropuerto de Tocumen, sumérjase en la biodiversidad de la selva tropical de Panamá. A bordo de un barco de expedición explore las aguas del Canal de Panamá a través de calas y ensenadas en busca de vida salvaje, incluidas innumerables especies de aves, reptiles y varias especies de monos. Disfrute de un paseo por la naturaleza y suba al Rainforest Discovery Center del Parque Nacional de Soberanía, que cuenta con una impresionante lista de 525 especies de aves y alberga también 105 especies de mamíferos. Visite un santuario de perezosos y exposiciones ecológicas, deslícese por la selva en un teleférico o, para los más activos, disfrute de una aventura en tirolina."
            }],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl: getURL('https://anconexpeditions.com/products/panama-s-spectacular-rainforest', true),
          },
        ],
        map: [
          {
            lat: 8.981513624474385,
            lng: -79.5248345315058,
          },
        ],
        gallery: AnconExpeditions.gallery,
        infoUrl: getURL("https://www.anconexpeditions.com/", true),
      },
      // Replace item end
      {
        id: 12,
        slug: "hover-tours",
        bannerImg: bannerHoverTours,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Hover Tours",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Hover Tours",
            subTitle: "Hover Tours",
            thirdTitle: "",
            infoUrl:
              "https://hovertours.com.pa/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1HoverTours,
            title: "Las tres ciudades de Panamá",
            subtitle: "",
            items: [
              {
                text: "Prepárense para vivir una experiencia imperdible durante su visita a Panamá.  Esta gira nos lleva a conocer los monumentos de Panamá Viejo, fundada en 1519 como la primera ciudad establecida por los españoles en las costas del Pacífico americano.  Seguido, disfrutaremos de espectaculares vistas entremezcladas de la ciudad moderna y el Casco Antiguo, segunda ciudad fundada en las costas Pacífico por los españoles en 1673, la cual conoceremos recorriendo sus estrechas y encantadoras callejuelas. Finalizamos nuestro recorrido con una vista impactante desde las islas de la Calzada de Amador.",
                feePerPerson: "",
              },
              {
                text: "Incluye:",
                feePerPerson: "",
              },
              {
                text: "Traslado de ida y vuelta desde su hotel en ciudad.",
                feePerPerson: "",
              },
              {
                text: "Entrada al Conjunto Monumental Histórico de Panamá La Vieja.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["No Incluye"],
            discount: "Alimentación, gastos personales y propinas",
            infoUrl:
              "https://hovertours.com.pa/panama-stopover/las-tres-ciudades-de-panama/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2HoverTours,
            title: "Descubre los Enigmas del Valle",
            subtitle: "",
            items: [
              {
                text: "¡Ven y descubre con nosotros lo que el Valle tiene para ofrecer!  Los impresionantes paisajes volcánicos de este hermoso pueblo coclesano, rodeado de riqueza natural, historia cultural y una increíble biodiversidad.",
                feePerPerson: "",
              },
              {
                text: "Incluye:",
                feePerPerson: "",
              },
              {
                text: "Traslado de ida y vuelta desde su hotel en ciudad",
                feePerPerson: "",
              },
              {
                text: "Entrada al Centro de Visitantes del Valle",
                feePerPerson: "",
              },
              {
                text: "Entrada al Chorro el Macho",
                feePerPerson: "",
              },
              {
                text: "Entrada a la Piedra Pintada",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["No Incluye"],
            discount: "Alimentación, gastos personales y propinas",
            infoUrl:
              "https://hovertours.com.pa/panama-stopover/descubre-los-enigmas-del-valle/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 9.017659252025135,
            lng: -79.51760742698679,
          },
        ],
        gallery: [gallery1HoverTours, gallery2HoverTours, gallery3HoverTours],
        infoUrl:
          "https://hovertours.com.pa/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 13,
        slug: "voyair-pty",
        bannerImg: bannerVoyairPTY,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Voyair PTY",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Voyair PTY",
            subTitle: "Voyair PTY",
            thirdTitle: "",
            infoUrl:
              "https://voyairpty.com/es/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1VoyairPTY,
            title:
              "Senderismo en la selva tropical en el Parque Nacional de Soberanía",
            subtitle: "",
            items: [
              {
                text: "Acompañado por nuestro guía bilingüe, caminará a través de un denso dosel tropical, buscando aves, monos, mariposas, ranas y muchas otras especies mientras escucha los comentarios informativos sobre la increíble biodiversidad de la región.",
                feePerPerson: "",
              },
              {
                text: "El sendero recomendado es The Plantation Road (según el día y la temporada), una antigua tierra de plantación de cacao que incluye secciones planas y montañosas que serpentean a través de un bosque maduro. La caminata toma aproximadamente 3 horas para caminar entre 7 a 10 kms (4 a 6 millas). Este sendero es un paraíso para la observación de aves y una puerta perfecta lejos de la ciudad adecuada para casi todos.",
                feePerPerson: "",
              },
              {
                text: "Incluye:",
                feePerPerson: "",
              },
              {
                text: "Transporte privado con aire acondicionado.",
                feePerPerson: "",
              },
              {
                text: "Guía bilingüe.",
                feePerPerson: "",
              },
              {
                text: "Una botella de agua.",
                feePerPerson: "",
              },
              {
                text: "Tarifa de entrada al Parque Nacional.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["No Incluye"],
            discount: "Desayuno, cualquier otra bebida o refrigerio",
            infoUrl:
              "https://voyairpty.com/tour/rainforest-hiking-at-the-soberania-national-park?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2VoyairPTY,
            title: "PASEO A CABALLO",
            subtitle: "",
            items: [
              {
                text: "Disfrute de una experiencia de montar a caballo, serpenteando a través de verdes colinas, cruzando ríos, pequeños arroyos y encontrando hermosos paisajes.",
                feePerPerson: "",
              },
              {
                text: "Después de una breve orientación sobre seguridad y técnica, tendrá más de una hora de cabalgata guiada por los senderos naturales. También puede nadar en algunos de los ríos de aguas cristalinas.",
                feePerPerson: "",
              },
              {
                text: "Un rancho privado, a solo 20 minutos del aeropuerto, es un destino ideal y agradable para explorar cabalgando en compañía de un buen caballo. Si eres lo suficientemente bueno para galopar, hay mucho espacio para hacerlo y, naturalmente, a los caballos les encanta. La temperatura es más fresca que en la ciudad de Panamá.",
                feePerPerson: "",
              },
              {
                text: "Duración: 4-5 horas",
                feePerPerson: "",
              },
              {
                text: "Incluye:",
                feePerPerson: "",
              },
              {
                text: "Agua embotellada",
                feePerPerson: "",
              },
              {
                text: "1 refresco / pop",
                feePerPerson: "",
              },
              {
                text: " ** Vehículo con aire acondicionado ** (si se selecciona la opción de transferencia)",
                feePerPerson: "",
              },
              {
                text: "Guía local",
                feePerPerson: "",
              },
              {
                text: "Cabalgata de hora y media (aproximadamente)",
                feePerPerson: "",
              },
              {
                text: "Todos los impuestos y tarifas",
                feePerPerson: "",
              },
              {
                text: "OPCIONAL: ALMUERZO BBQ",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [""],
            discount:
              "Actividad fácil a moderada. Ninguna experiencia la necesita. La edad mínima recomendada es de 9 años. Niños menores de 7 años Gratis y deben viajar con uno de sus padres. Peso máximo permitido 120 Kgs. El tour puede cambiarse o cancelarse debido a las condiciones climáticas. Duración de la escursión: Aproximadamente 5 horas. El personal habla español e inglés",
            infoUrl:
              "https://voyairpty.com/tour/horseback-riding?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 9.07451488191963,
            lng: -79.65982676034103,
          },
        ],
        gallery: [gallery1VoyairPTY, gallery2VoyairPTY, gallery3VoyairPTY],
        infoUrl:
          "https://voyairpty.com/es/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 14,
        slug: "trip-de-jenny",
        bannerImg: bannerTripJenny,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Trip de Jenny",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Trip de Jenny",
            subTitle: "Trip de Jenny",
            thirdTitle: "",
            infoUrl:
              "https://eltripdejenny.com/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1TripJenny,
            title: "Aventuras Extremas en Colón - Full Day",
            subtitle: "",
            items: [
              {
                text: "Si pensaba que Colón es solo sol, playa y arena, se equivoca… En el Caribe tenemos una  naturaleza variada y lugares para explorar.",
                feePerPerson: "",
              },
              {
                text: "Desde historias de piratas, senderos cargados de historia militar, hasta el lanzamiento de una tirolesa de 700 metros de largo sobre el lago Gatún, esto es todo lo que este súper día de aventuras en Colón tiene para ofrecer. En este viaje, visitaremos el Castillo San Lorenzo y La Granja Campo Aventura, en un solo día.",
                feePerPerson: "",
              },
              {
                text: "Parada 1: Nuestra primera parada será en el bosque protector de San Lorenzo, donde recorreremos los senderos para comprender la naturaleza tropical. Al llegar al Castillo San Lorenzo, viajaremos en el tiempo y hablaremos de esta importante fortificación durante la colonia española",
                feePerPerson: "",
              },
              {
                text: "Parada 2: Colón es famoso por su deliciosa cocina. Disfrutaremos de un almuerzo caribeño preparado por los lugareños. Prepárate porque siempre nos sorprenden con una especialidad extra en el menú.",
                feePerPerson: "",
              },
              {
                text: "Parada 3: Prepárate para nuestra próxima parada de aventuras. Finalmente, llega a La Granja Campo y Aventura para disfrutar de este circuito ¡prepárate solo para ti!.",
                feePerPerson: "",
              },
              {
                text: "Preparemos nuestro equipo de seguridad para montar adrenalina en una tirolesa de 700 mts sobre el lago Gatún, aceptar el desafío de cruzar cinco puentes colgantes o simplemente relajarse en kayak en el lago Gatún para disfrutar de la vista del bosque tropical",
                feePerPerson: "",
              },
              {
                text: "Regreso a la ciudad de Panamá a las 4:00 p.m.",
                feePerPerson: "",
              },
              {
                text: "INCLUYE:",
                feePerPerson: "",
              },
              {
                text: "Transporte desde Ciudad de Panamá - Colón ida y vuelta.",
                feePerPerson: "",
              },
              {
                text: "Caminata guiada por los principales senderos del santuario del Castillo de San Lorenzo. (3km).",
                feePerPerson: "",
              },
              {
                text: "Canopy en La Granja Campo y Aventura.",
                feePerPerson: "",
              },
              {
                text: "Kayak sobre el lago Gatún.",
                feePerPerson: "",
              },
              {
                text: "Almuerzo caribeño.",
                feePerPerson: "",
              },
              {
                text: "Aperitivos, frutas y agua.",
                feePerPerson: "",
              },
              {
                text: "Repelente de mosquitos orgánico / biodegradable.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["No Incluye"],
            discount:
              "Bebidas alcohólicas ni recuerdos. No es un tour privado, podría unirse otros viajeros",
            infoUrl:
              "https://eltripdejenny.com/producto/colon-extreme-adventure-full-day/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2TripJenny,
            title: "Explora el Caribe",
            subtitle: "",
            items: [
              {
                text: "Si te gusta disfrutar del mar y sus atractivos pero más relajado. Este viaje es para ti.",
                feePerPerson: "",
              },
              {
                text: "Disfrute de un día completo en Puerto Lindo. Un pequeño pueblo de pescadores rodeado de la naturaleza virgen y exótica de Colón.",
                feePerPerson: "",
              },
              {
                text: "Ven a explorar el Túnel del Amor, un túnel natural formado por manglares. La isla de los Monos para observar desde el barco a esta especie en su hábitat natural y terminar en las relajantes aguas cristalinas de las piscinas naturales",
                feePerPerson: "",
              },
              {
                text: "Visite Isla Mamey y relájese con un agua de coco fresca.",
                feePerPerson: "",
              },
              {
                text: "Completa tu aventura con un almuerzo al estilo caribeño: arroz con coco, pescado fresco, patacones y ensalada.",
                feePerPerson: "",
              },
              {
                text: "Incluye:",
                feePerPerson: "",
              },
              {
                text: "Transporte Panamá / Puerto Lindo ida y vuelta.",
                feePerPerson: "",
              },
              {
                text: "Visita al 'Túnel del Amor', Isla Mamey, Piscina Natura.",
                feePerPerson: "",
              },
              {
                text: "Bebida de Bienvenida.",
                feePerPerson: "",
              },
              {
                text: "Almuerzo Caribeño.",
                feePerPerson: "",
              },
              {
                text: "Agua, Refrigerios y frutas a bordo.",
                feePerPerson: "",
              },
              {
                text: "Kit de Snorkelling.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["No Incluye"],
            discount: "Bebidas alcohólicas ni recuerdos",
            infoUrl:
              "https://eltripdejenny.com/producto/explore-caribe-full-day-experience/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 9.359637941390362,
            lng: -79.89374823152576,
          },
        ],
        gallery: [gallery1TripJenny, gallery2TripJenny, gallery3TripJenny],
        infoUrl:
          "https://eltripdejenny.com/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 15,
        slug: "tree-trek-gamboa",
        bannerImg: bannerTreeTrekGamboa,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Tree Trek Gamboa",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Tree Trek Gamboa",
            subtitle: "Canopy en Gamboa",
            thirdtitle: "",
            infoUrl:
              "https://reservas.boquetetreetrek.com/gamboa/tours-207c8743-254a-4fff-9ca9-540fd95ac122/p/tour-canopy-gamboa?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "Recorre el bosque nublado muy cerca de la Ciudad de Panamá de forma diferente volando por los aires hasta 80 kms por el dosel del bosque a través de canopy. Son 10 cables y 13 plataformas llenas de adrenalina y biodiversidad. Un recorrido lleno de mucha alegría, fauna y flora de la zona que no puedes perder la oportunidad de no realizarlo. Tenemos 3 horarios de viernes a domingo ( 9:30 am | 11:30 am | 2:30 pm)",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "- Se requiere 24 horas de anticipación para hacer devolución del 80% del pago a la reserva. Las cancelaciones recibidas con menos de 24 horas tendrán un cargo de tarifa completa. </p><p>-Nos reservamos el derecho de cancelar un tour antes de que inicie, debido a un acto de naturaleza, tales como: fuerte lluvias, vientos fuertes o alguna situación que implique un riesgo para la integridad de nuestros clientes. Si cancelamos el tour, serán reembolsados al 100% o reasignados a una fecha posterior de acuerdo mutuo.</p><p>- Desde 5 años en adelante (van acompañados de un guía). Con el consentimiento de los padres o acompañados por uno de ellos.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 9.12193325994732,
            lng: -79.69269418732473,
          },
        ],
        gallery: [
          gallery1TreeTrekGamboa,
          gallery2TreeTrekGamboa,
          gallery3TreeTrekGamboa,
        ],
        infoUrl:
          "https://reservas.boquetetreetrek.com/gamboa/tours-207c8743-254a-4fff-9ca9-540fd95ac122/p/tour-canopy-gamboa?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 16,
        slug: "ecocircuitos",
        bannerImg: bannerEcocircuitos,
        breadcrumb: [
          {
            indice: "Ecocircuitos",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Ecocircuitos",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Ecocircuitos",
            subTitle: "Ecocircuitos",
            thirdTitle: "",
            infoUrl:
              "https://ecocircuitos.com/stopover/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1Ecocircuitos,
            title: "Tránsito Parcial por el Canal de Panamá ",
            subtitle: "",
            items: [
              {
                text: "El Canal de Panamá es la atracción turística más popular en Panamá y la mejor manera de conocer su funcionamiento e historia es realizando uno de nuestros tours por el Canal de Panamá en el Pacific Queen. Durante su gira tendrá la oportunidad de ver la operación del canal de cerca, transitando la vía con los grandes barcos. De igual manera podrá ver los remolcadores operando y por supuesto la esclusas. También podrá observar donde están ubicadas las nuevas esclusas.",
                feePerPerson: "",
              },
              {
                text: "En el tour parcial por el Canal de Panamá, navegaremos por las esclusas de Miraflores y de Pedro Miguel. Pasaremos por el Corte Culebra y por debajo de los Puentes Centenario y de las Américas.",
                feePerPerson: "",
              },
              {
                text: "El tour incluye:",
                feePerPerson: "",
              },
              {
                text: "Traslado hotel-barco-hotel (desde hoteles seleccionados)",
                feePerPerson: "",
              },
              {
                text: "Almuerzo",
                feePerPerson: "",
              },
              {
                text: "Gaseosas y aguas",
                feePerPerson: "",
              },
              {
                text: "Guía bilingüe",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duración: 5-6 horas aproximadamente"],
            discount: "",
            infoUrl:
              "https://ecocircuitos.com/stopover/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2Ecocircuitos,
            title: "Kayak por la Cuenca del Canal",
            subtitle: "",
            items: [
              {
                text: "Esta gira comienza en la Marina privada de Gamboa, en donde nuestro guía experto en naturaleza les dará un entrenamiento introductorio en seguridad, uso de los remos y manejo del kayak. Seguidamente, navegaremos remando por el Río Chagres, mientras conocemos por parte de nuestro guía, acerca de la diversa flora y fauna que se ve en el área y de la importancia del Parque Nacional Soberanía.",
                feePerPerson: "",
              },
              {
                text: "Tiempo de duración: 3 horas (no contando con traslados).",
                feePerPerson: "",
              },
              {
                text: "Qué traer: ropa confortable que se pueda mojar tipo de playa, protector solar, gorra, repelente de mosquitos",
                feePerPerson: "",
              },
              {
                text: "Qué incluye: kayaks y todo el equipo del mismo, agua en botella, snacks, guía experto en kayak y naturaleza, bote escolta.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["No Incluye"],
            discount: "traslado hacia y desde de Gamboa.",
            infoUrl:
              "https://ecocircuitos.com/stopover/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 8.995028517483584,
            lng: -79.50829740266965,
          },
        ],
        gallery: [
          gallery1Ecocircuitos,
          gallery2Ecocircuitos,
          gallery3Ecocircuitos,
        ],
        infoUrl:
          "https://eltripdejenny.com/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 17,
        slug: "scuba-panama",
        bannerImg: bannerScubaPanama,
        breadcrumb: [
          {
            indice: "Scuba Panama",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Scuba Panama",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Scuba Panama",
            subTitle: "",
            thirdTitle: "",
            infoUrl: "",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1ScubaPanama,
            title: "Buceo en el Atlantico - day tour",
            subtitle: "",
            items: [
              {
                text: "Se requiere certificacion de buceo para este tour.",
                feePerPerson: "",
              },
              {
                text: "$250 un buzo / $165 dos o mas buzos",
                feePerPerson: "",
              },
              {
                text: "El tour incluye:",
                feePerPerson: "",
              },
              {
                text: "Transporte terrestre ida y vuelta la ciudad de Panama a Portobelo",
                feePerPerson: "",
              },
              {
                text: "Equipo de buceo",
                feePerPerson: "",
              },
              {
                text: "Dos tanques",
                feePerPerson: "",
              },
              {
                text: "Guia de buceo",
                feePerPerson: "",
              },
              {
                text: "Salida en bote de buceo",
                feePerPerson: "",
              },
              {
                text: "Almuerzo",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [
              "De necesitar equipo snorkel, lo puede alquilar por $15 adicionales.",
            ],
            discount: "",
            infoUrl:
              "https://www.scubapanama.com/es/turismo-en-panama.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2ScubaPanama,
            title: "Kayak por la Cuenca del Canal",
            subtitle: "",
            items: [
              {
                text: "Se requiere certificacion de buceo para este tour.",
                feePerPerson: "",
              },
              {
                text: "$200 por buzo al ser 4 o mas buzos",
                feePerPerson: "",
              },
              {
                text: "El tour incluye:",
                feePerPerson: "",
              },
              {
                text: "Transporte terrestre ida y vuelta la ciudad de Panama a Portobelo",
                feePerPerson: "",
              },
              {
                text: "Equipo de buceo",
                feePerPerson: "",
              },
              {
                text: "Dos tanques",
                feePerPerson: "",
              },
              {
                text: "Guia de buceo",
                feePerPerson: "",
              },
              {
                text: "Salida en bote de buceo",
                feePerPerson: "",
              },
              {
                text: "Almuerzo",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [
              "De necesitar equipo snorkel, lo puede alquilar por $15 adicionales.",
            ],
            discount: "",
            infoUrl:
              "https://www.scubapanama.com/es/turismo-en-panama.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [],
        gallery: [
          gallery1ScubaPanama,
          gallery2ScubaPanama,
          gallery3ScubaPanama,
        ],
        infoUrl: "",
      },
      //Replace line start
      {
        id: 18,
        slug: "panama-natural",
        bannerImg: PanamaAlNatural.banner,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Panama Natural",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Panama Natural",
            subtitle: "",
            thirdtitle: "",
            infoUrl: "",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: PanamaAlNatural.tour.canal,
            title: "Panama Canal + Causeway de Amador tour de medio día.",
            subtitle: "",
            items: [
              {
                text: "Salidas diarias: 9:00 a.m. y 2:00 p.m.",
                feePerPerson: "",
              },
              {
                text: "Iniciamos nuestro recorrido en el centro",
                feePerPerson: "",
              },
              {
                text: "*No incluye el costo de las entradas al Centro de Visitantes*",
                feePerPerson: "",
              },
              {
                text: "*Opcional: almuerzo o cena en Restaurante Sabroso Panamá",
                feePerPerson: "",
              },
              {
                text: "Estaremos frente a la octava maravilla del mundo - El Canal de Panamá, donde podremos admirar desde la terraza la operación de las esclusas mientras observamos el tránsito de los enormes barcos.",
                feePerPerson: "",
              },
              {
                text: "Luego nos trasladaremos hacia el Causeway de Amador, cuatro islas unidas por una carretera construida con las rocas que se extraían en los trabajos del Canal de Panamá. Desde aquí podrás encontrar el spot perfecto para hacer fotografías de la entrada al canal y de la vista panorámica a la ciudad de Panamá.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [
              "De necesitar equipo snorkel, lo puede alquilar por $15 adicionales.",
            ],
            discount: "",
            infoUrl:
              getURL("https://panamaalnatural.com/our-destination/panama-canal-causeway/", true),
          },
          {
            img: PanamaAlNatural.tour.ciudad,
            title: "Panama City: La Experiencia",
            subtitle: "",
            items: [
              {
                text: "Visitaremos el Sitio Arqueológico de Panamá Viejo, el cual corresponde al asiento donde fue fundada la ciudad de Panamá el 15 de agosto de 1519 por Pedrarias Dávila y comprende las ruinas del primer asentamiento europeo sobre el litoral pacífico americano, así como los vestigios de los primeros habitantes del istmo. Declarado Conjunto Monumental Histórico desde 1976.",
                feePerPerson: "",
              },
              {
                text: "Haremos un recorrido interesante por la Panamá Moderna, para conocer sus rascacielos más emblemáticos como la F&F Tower, mejor conocido como el Edificio del Tornillo,  The Ocean Club Tower, Tower Financial Center, entre muchos otros que representan a la cosmopolita Ciudad de Panamá.",
                feePerPerson: "",
              },
              {
                text: "Continuaremos nuestro recorrido para visitar el Casco Antiguo de Panamá, Patrimonio Cultural de la Humanidad desde 1982 disfrutando de su arquitectura, plazas, el Teatro Nacional y las calles adoquinadas que desafían la implacabilidad del tiempo, es la oportunidad mágica de tener un encuentro con más de cuatro siglos de historia.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [
              "De necesitar equipo snorkel, lo puede alquilar por $15 adicionales.",
            ],
            discount: "",
            infoUrl:
              getURL("https://panamaalnatural.com/our-destination/panama-city-la-experiencia/", true),
          },
        ],
        map: [],
        gallery: PanamaAlNatural.gallery,
        infoUrl: getURL("https://panamaalnatural.com/our-destination/panama-canal-causeway/", true),
      },
      {
        id: 20,
        slug: "museo-de-la-mola",
        bannerImg: museoDeLaMola.banner,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Museo de la Mola",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Mueso de la Mola",
            subtitle: "",
            thirdtitle: "",
            infoUrl: "https://museodelamola.org/",
          },
        ],
        extraInfo: [
          {
            description:
              "El Museo de la Mola (MuMo) invita a los visitantes a explorar y conocer sobre las molas desde su aspecto artístico a través de sus cinco exhibiciones. La entrada y la visita guiada al museo son gratuitas. La visita guiada es por disponibilidad al momento de la llegada.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "Todos los visitantes del MUMO podrán disfrutar de un exclusivo 20% de descuento en sus compras en Attenza Duty Free en Aeropuerto Internacional de Tocumen; con solo ingresar al comprar en Attenza.net, el código de descuento que les será otorgado en el museo.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "https://www.attenza.net",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.9522419,
            lng: -79.535641,
          },
        ],
        gallery: museoDeLaMola.gallery,
        infoUrl: "https://museodelamola.org/",
      },
      //Replace line end
      {
        id: 21,
        slug: "poin-panama",
        bannerImg: poinPanama.banner,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Poin Panama",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Poin Panama",
            subtitle: "",
            thirdtitle: "",
            infoUrl: "https://www.poinpanama.com/es",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: poinPanama.tour.border,
            title: "El Borde",
            subtitle: "",
            items: [
              {
                text: "Suspendete a más de 500 pies sobre el suelo y prepárate para la mejor aventura en un lugar único e increíble, diseñado y pensado para los amantes de las alturas. Desde este punto podrás hacerte la foto perfecta con la ciudad moderna y la histórica de una manera diferente y divertida.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Código de Descuento"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/es",
          },
          {
            img: poinPanama.tour.swing,
            title: "El Columpio",
            subtitle: "",
            items: [
              {
                text: "Prepárate para columpiarte desde lo más alto de uno de los edificios de la Ciudad de Panamá, con una vista de lujo. Impúlsate y siente la adrenalina fluyendo por tus venas, pero lo más importante; no  olvides disfrutar de la vista.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Código de Descuento"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/es",
          },
          {
            img: poinPanama.tour.viewpoint,
            title: "Mirador de Cristal + Gafas Paseo Virtual 360°",
            subtitle: "",
            items: [
              {
                text: "¡Una experiencia única! Colócate sobre el primer Mirador de Cristal de la Ciudad de Panamá y más imponente de Centro América. Descubre cómo los autos pasan bajo tus pies mientras ves desde un mismo punto la ciudad moderna y la historia de Panamá, disfruta luego de un tour virtual 360° que te llevará a recorrer las maravillas que tiene la Ciudad de Panamá, con rincones y vistas increíbles.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Código de Descuento"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/es",
          },
          {
            img: poinPanama.tour.zipline,
            title: "Zipline",
            subtitle: "",
            items: [
              {
                text: "El Zipline llenará todas tus expectativas de sobrevolar uno de los puntos más visitados por los viajeros de diferentes partes del mundo en la Ciudad de Panamá. Esta atracción sin duda, subirá la adrenalina de aquellos amantes de lo extremo. Este espacio de entretenimiento ha sido pensado bajo los estándares más altos de seguridad, para que todas las personas que deseen volar, sientan que sus sueños se hicieron realidad.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Código de Descuento"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/es",
          },
          {
            img: poinPanama.tour.package,
            title: "Paquete Completo",
            subtitle: "",
            items: [
              {
                text: "Si no visitas POIN no has visitado la ciudad de Panamá. Ven y descubre cada una de las actividades con este paquete completo que te llevará a conocer la ciudad moderna y la historia de la Ciudad de Panamá, de forma diferente desde un mismo punto.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Código de Descuento"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/es",
          },
        ],
        map: [
          {
            lat: 8.961454,
            lng: -79.5396003,
          },
        ],
        gallery: poinPanama.gallery,
        infoUrl: "https://www.poinpanama.com/es",
      },
      //add lines start
      {
        id: 22,
        slug: "go-panama-bike-tours",
        bannerImg: GoPanamaBikeTours.banner,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Go Panama Bike Tours",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Go Panama Bike Tours",
            subtitle: "",
            thirdtitle: "",
            infoUrl: getURL('https://gopanamabiketours.com/es', true),
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: GoPanamaBikeTours.tour.panamaNights,
            title: "Pananights",
            subtitle: '',
            items: [
              {
                text: "¡Descubre la magia de Panamá de noche con nuestro emocionante tour, PANANIGHTS! Embárcate en un fresco recorrido caminando por la ciudad, explorando las plazas principales mientras nuestro guía experto te cuenta la fascinante historia detrás de cada lugar. Para culminar esta experiencia inolvidable, te invitamos a una degustación de ron y otras bebidas nacionales, donde podrás disfrutar de los sabores auténticos de Panamá."
              },
              {
                text: 'Duración: 2.5 a 3 horas',
              },
              {
                text: 'Horario: 7:30 Pm',
              },
              {
                text: 'Disponibilidad: miércoles a sábado',
              },
              {
                text: '4 plazas históricas',
              },
              {
                text: 'Guía Local Bilingüe',
              },
              {
                text: 'Bebidas Alcohólicas',
              },
              {
                text: 'Entradas a los lugares',
              },
              {
                text: 'Degustación de Ron Panameño',
              },
            ],
            feePerPerson: "",
            tips: [],
            discount: "Mínimo: 2 personas",
            infoUrl: getURL('https://gopanamabiketours.com/es/pananights-copa-stop-over-one-day-tour/', true),
          },
          {
            img: GoPanamaBikeTours.tour.casco,
            title: "Tour en bicicleta en la Ciudad de Panamá y Casco Antiguo",
            subtitle: '',
            items: [
              {text: "¡Descubre Panamá de una manera única con nuestro tour en bicicleta! Únete a nosotros y explora lo más emblemático de la ciudad mientras pedaleas a través del casco antiguo y disfrutas de la moderna cinta costera. Nuestros expertos guías te llevarán en un viaje inolvidable, donde podrás sumergirte en la rica historia y vibrante cultura de Panamá. Esta experiencia en bicicleta te permitirá aprovechar al máximo tu tiempo en esta encantadora ciudad. ¡No te pierdas esta oportunidad única de explorar Panamá en bicicleta!"},
              {
                text: 'Visita Casco Viejo, diferentes plazas y mercados',
              },
              {
                text: 'Visita Cinta Costera y los rascacielos de Panamá',
              },
              {
                text: 'Tour guiado de 2.5 a 3 horas',
              },
              {
                text: 'Bicicletas nuevas de montaña (Bici Eléctrica con $10 Extra)',
              },
              {
                text: 'Casco',
              },
              {
                text: 'Botella de agua fresca',
              },
              {
                text: 'Guía bilingüe',
              },
              {
                text: 'Punto de encuentro: Go Panama Bike Tours - Casco Viejo',
              },
              {
                text: 'Disponibilidad: Todos los días 9:30 Am y 3:30 Pm',
              },
              {
                text: 'Duración: 2.5 a 3 horas',
              },
              {
                text: 'Nivel: Fácil',
              },
            ],
            feePerPerson: "",
            tips: [],
            discount: "Mínimo: 2 personas",
            infoUrl: getURL('https://gopanamabiketours.com/es/bike-tour-one-day-tour/', true),
          }
        ],
        map: [
          {
            lat: 8.9525138,
            lng: -79.5360486,
          },
        ],
        gallery: GoPanamaBikeTours.gallery,
        infoUrl: getURL('https://gopanamabiketours.com/es', true),
      },
      {
        id: 23,
        slug: "our-panama",
        bannerImg: OurPanama.banner,
        breadcrumb: [
          {
            indice: "Tours de un día",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Our Panama",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Our Panama",
            subtitle: "",
            thirdtitle: "",
            infoUrl: getURL('https://www.ourpanama.com/', true),
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: OurPanama.tour.venas,
            title: "El historico Portobelo y Venas Azules",
            subtitle: "",
            items: [
              {
                text: 'Transporte desde la ciudad de Panamá',
              },
              {
                text: 'Traslado Marítimo',
              },
              {
                text: 'Almuerzo caribeño con bebida',
              },
              {
                text: 'Agua en Tetra Pack',
              },
              {
                text: 'Entrada a Playas y Alrededores',
              },
              {
                text: 'Equipo de snorkel',
              },
              {
                text: 'Observación de perezosos',
              },
              {
                text: 'Tour de snorkel',
              },{
                text: 'Visita Guiada del Pueblo con Explicación',
              },
            ],
            feePerPerson: "",
            tips: ['Duración del viaje: aproximadamente 8 horas'],
            discount: "Mínimo: 2 personas",
            infoUrl: getURL('https://www.ourpanama.com/producto/la-historica-ciudad-de-portobelo-y-al-paraiso-del-caribe-venas-azules-privado', true),
          },
          {
            img: OurPanama.tour.guna,
            title: "San Blas day tour",
            subtitle: "",
            items: [
              {
                text: 'Transporte Terrestre 4x4 Compartido',
              },
              {
                text: 'Transporte Acuático',
              },
              {
                text: 'Almuerzo y una bebida.',
              },
              {
                text: 'Impuestos portuarios e insulares',
              },
              {
                text: 'Guía turístico local bilingüe.',
              },
              {
                text: 'Visita a 2 islas (Fragata y Perro Chico)',
              },
              {
                text: 'Tours a piscinas naturales',
              },
            ],
            feePerPerson: "",
            tips: ['Duración del Tour: Aproximadamente 8 horas'],
            discount: "Mínimo 1 persona",
            infoUrl: getURL('https://gopanamabiketours.com/es/bike-tour-one-day-tour/', true),
          }
        ],
        map: [
          {
            lat: 8.9888563,
            lng: -79.5310096,
          },
        ],
        gallery: OurPanama.gallery,
        infoUrl: getURL('https://www.ourpanama.com/', true),
      },
      //add lines end
    ],
  },
  // ENGLISH///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  en: {
    name: "Tours",
    tours: [
      {
        id: 0,
        slug: "biomuseo",
        bannerImg: bannerBiomuseo,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Biomuseo",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Biomuseo",
            subtitle: "Biomuseo.",
            thirdtitle: "",
            infoUrl:
              "https://biomuseo.org/en",
          },
        ],
        extraInfo: [
          {
            description:
              "It was designed by the famous architect Frank Gehry and it represents his first architectural work in Latin America. The museum exhibits Panama’s nature and biodiversity. One of a kind in the region, it includes a public atrium, a space for seasonal exhibitions, a store, a cafeteria, and many outdoor exhibitions in a botanic park. Open Saturday and Sunday from 1PM to 5PM",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "<strong>USD 15.00</strong> (adults) Special fare for Stopover passengers",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.932401471861311,
            lng: -79.54458165917156,
          },
        ],
        gallery: [gallery1Biomuseo, gallery2Biomuseo, gallery3Biomuseo],
        infoUrl:
          "https://biomuseo.org/en",
      },
      {
        id: 1,
        slug: "canopy-tower",
        bannerImg: bannerCanopyTower,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Canopy Tower",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Canopy Tower",
            subtitle: "Canopy Tower.",
            thirdtitle: "",
            infoUrl:
              "https://www.canopytower.com/canopy-tower/things-to-do/day-visits?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "15% discount on half day visits. All of our bird watching tours are led by experienced, bilingual, birding/nature guides who carry Leica binoculars and a Leica scope. Our groups are a maximum of 8 birders and one guide. Our Panama day tours include one delicious meal, entrance fee to Soberanía National Park, and use of the Canopy Tower’s Observation Deck where our guides will help you identify wildlife.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "Please note that our Panama day tours are subject to guide and space availability and we will do our best to accommodate outside visitors to the Canopy Tower; however, priority for spaces in wildlife tours is given to lodge guests. Prices are per person and do not include 7% ITBMS.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 9.077799693777974,
            lng: -79.64920018985872,
          },
        ],
        gallery: [
          gallery1CanopyTower,
          gallery2CanopyTower,
          gallery3CanopyTower,
          gallery4CanopyTower,
          gallery5CanopyTower,
        ],
        gallery: [
          gallery1CanopyTower,
          gallery2CanopyTower,
          gallery3CanopyTower,
          gallery4CanopyTower,
          gallery5CanopyTower,
        ],
        infoUrl:
          "https://www.canopytower.com/canopy-tower/things-to-do/day-visits?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 2,
        slug: "museo-de-arte-contemporaneo",
        bannerImg: bannerMuseoContemporaneo,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Museum of Contemporary Art",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Museum of Contemporary Art",
            subtitle: "Museum of Contemporary Art.",
            thirdtitle: "",
            infoUrl:
              "https://macpanama.org/products/panama-stopover-kit?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "Live a unique experience at MAC PANAMA with a personalized guided tour, welcome snacks, and a souvenir kit with a gift from the museum. Take a tour of our exhibition with the museum guides so that you can learn in-depth the concept of the works, the history of its artists, key questions for reflection, and the details of the history of the only art museum in the country.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "Visits are available from Friday to Sunday from 10 am to 5 pm. Passengers must reserve their day and time of visit by sending an email to info@macpanama.org. This promotion will be valid only for Stopover Copa Airlines passengers. Passengers must show their online payment receipt and Stopover ticket at reception",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.960866490996098,
            lng: -79.54304734923971,
          },
        ],
        gallery: [
          gallery1MuseoContemporaneo,
          gallery2MuseoContemporaneo,
          gallery3MuseoContemporaneo,
          gallery4MuseoContemporaneo,
        ],
        infoUrl:
          "https://macpanama.org/products/panama-stopover-kit?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 3,
        slug: "panama-helicopter-tours",
        bannerImg: bannerHelicopter,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Panama Helicopter Tour",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Panama Helicopter Tour",
            subTitle: "",
            thirdTitle: "",
            infoUrl:
              "https://panamahelicoptertours.com/#!/tours?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "Tours are available Monday to Sunday from 7:00am-5:00 pm. There is a possibility that the helicopter will be shared with other tour passengers; Please note that the flight time can vary due to a number of circumstances; average flight time is between 5 to 9 minutes. Passengers must weight 250 lbs or less",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1Helicopter,
            title: "Panama Helicopter Tour",
            subtitle:
              "Package 1: Helicopter City Tour. Includes the following:",
            items: [
              {
                text: "Departure and return from/to Albrook airport.",
                feePerPerson: "",
              },
              {
                text: "Helicopter tour over PANAMA CITY.",
                feePerPerson: "",
              },
              {
                text: "Overhead views of notable attractions such as Panama Viejo, Balboa, Costa del Este, Causeway, Casco Viejo, Panama canal entrance, and others.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duration: 30 minutes."],
            discount: "Minimum of three people",
            infoUrl:
              "https://panamahelicoptertours.com/#!/tours/city-pacific-canal-tour-pacific-side?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2Helicopter,
            title: "Helicopter Ocean 2 Ocean",
            subtitle:
              "Package 2: Helicopter Ocean 2 Ocean. Includes the following:",
            items: [
              {
                text: "Departure from Albrook airport.",
                feePerPerson: "",
              },
              {
                text: "Helicopter tour over PANAMA CITY and canal tour.",
                feePerPerson: "",
              },
              {
                text: "Overhead views of notable attractions such as Panama Canal, Port of Colon, San Lorenzo Fort, Chagres River, Costa del Este, Balboa Skyscrapers, Marina, Yacht Club, Causeway, Casco Viejo, and More., and return to Panama City.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duration: 1 hour."],
            discount: "Minimum of three people",
            infoUrl:
              "https://panamahelicoptertours.com/#!/tours/city-canal-tour-from-pacific-to-atlantic-side?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 8.968293347089691,
            lng: -79.55837985953471,
          },
        ],
        gallery: [
          gallery1Helicopter,
          gallery2Helicopter,
          gallery3Helicopter,
          gallery4Helicopter,
          gallery5Helicopter,
        ],
        gallery: [
          gallery1Helicopter,
          gallery2Helicopter,
          gallery3Helicopter,
          gallery4Helicopter,
          gallery5Helicopter,
        ],
        infoUrl:
          "https://panamahelicoptertours.com/#!/tours?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 4,
        slug: "panama-rainforest-discovery-center",
        bannerImg: bannerRainforest,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Panama Rainforest Discovery Center",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Panama Rainforest Discovery Center",
            subtitle: "Panama Rainforest Discovery Center.",
            thirdtitle: "",
            infoUrl:
              "https://pipelineroad.org/en/stop-over?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "A tropical rainforest center located 20 minutes away from Panama City. This center promotes environmental education and sustainable tourism.",
            open: "Open on Friday, Saturday, and Sunday from 6:00 am to 4:00 pm.",
            social: [
              {
                name: "Instagram",
                url: "https://www.instagram.com/panama_rainforest_dc?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
                text: "@panama_rainforest_dc",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 9.13377853509827,
            lng: -79.72066697636642,
          },
        ],
        gallery: [gallery1Rainforest, gallery2Rainforest, gallery3Rainforest],
        infoUrl:
          "https://pipelineroad.org/en/stop-over?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 5,
        slug: "panama-sailing-tours",
        bannerImg: bannerPanamaSailingTours,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Panama Sailing Tours",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Panama Sailing Tours",
            subtitle: "Panama Sailing Tours.",
            thirdtitle: "",
            infoUrl:
              "http://panamasailingtours.com/cat_ing/inclusive-taboga-day-tour/?fbclid=IwAR1yfhj_L9_vAK9DaydlJ98ceU2ysgj1x5mYWxEUPoPylGp3yf13X7XtqEA?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "All Inclusive Catamaran Excursion to Taboga Island",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "This is the perfect way to have an extraordinary day. With our all inclusive plan you can spend the day in one of the closest Island to the City. We have lots of entertainment for adults and kids! Includes: Open Bar, Snacks, Lunch, Animation, Visit to the back side of the Island, Visit to the beach.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.94196752051534,
            lng: -79.55561528303423,
          },
        ],
        gallery: [
          gallery1PanamaSailingTours,
          gallery2PanamaSailingTours,
          gallery3PanamaSailingTours,
        ],
        gallery: [
          gallery1PanamaSailingTours,
          gallery2PanamaSailingTours,
          gallery3PanamaSailingTours,
        ],
        infoUrl:
          "http://panamasailingtours.com/cat_ing/inclusive-taboga-day-tour/?fbclid=IwAR1yfhj_L9_vAK9DaydlJ98ceU2ysgj1x5mYWxEUPoPylGp3yf13X7XtqEA?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 6,
        slug: "allegro-tours",
        bannerImg: bannerAllegroTours,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Allegro Tours",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Allegro Tours",
            subTitle: "",
            thirdTitle: "",
            infoUrl:
              "https://allegrotours.net/experiences/detail/60a688126729e7d89b6577ea?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1AllegroTours,
            title: "Full Day Panamá City & Mall",
            subtitle: "Package includes the following:",
            items: [
              {
                text: "We invite you to a tour of the most emblematic places of Panama City and a half-day shopping stop in one of the main Malls with the greatest tourist attraction, Albrook Mall or Multiplaza Mall.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [],
            discount: "",
            infoUrl:
              "https://allegrotours.net/experiences/detail/60a688126729e7d89b6577ea?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2AllegroTours,
            title: "PANAMA EXPRESS",
            subtitle: "Package includes the following:",
            items: [
              {
                text: "We invite you to know the wonderful City of Panama.",
                feePerPerson: "",
              },
              {
                text: "A tour of: Old Panama, panoramic visit to the Biomuseum, the Amador Causeway and the Old Town.",
                feePerPerson: "",
              },
              {
                text: "Roundtrip transfer to the airport.",
                feePerPerson: "",
              },
              {
                text: "Visit through the assisted city.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl:
              "https://allegrotours.net/experiences/detail/60a687276729e7d89b6577e9?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 8.98383207302121,
            lng: -79.52322448465601,
          },
        ],
        gallery: [
          gallery1AllegroTours,
          gallery2AllegroTours,
          gallery3AllegroTours,
          gallery4AllegroTours,
        ],
        infoUrl: "",
      },
      {
        id: 7,
        slug: "rge-travel",
        bannerImg: bannerRGETravel,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "RGE Travel",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "RGE Travel",
            subTitle: "",
            thirdTitle: "",
            infoUrl:
              "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1RGETravel,
            title: "City Tour & Monkey Tours",
            subtitle: "Package includes the following:",
            items: [
              {
                text: "We start with the Old Town, which offers a unique sense of tradition and transition. Old buildings of three and four floors, with red tile roofs and wrought iron bars, around balconies adorned with plant pots, surround historic squares and impressive ruins. We continue with our tour of the financial district which is located in the heart of the city. Panama City until we reach Gamboa where we will take a boat to live a new experience and venture to navigate the Panama Canal, we will travel through Gatun Lake until we reach the famous monkey island where we will interact with different types of monkeys and you can enjoy of beautiful landscapes in the middle of the jungle.",
                feePerPerson: "",
              },
              {
                text: "Specialized guides",
                feePerPerson: "",
              },
              {
                text: "Departure at 7:00 am / return at 1:00 pm.",
                feePerPerson: "",
              },
              {
                text: "Shared service.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl:
              "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2RGETravel,
            title: "City Tour + Monkey Tours & Embera",
            subtitle: "Package includes the following:",
            items: [
              {
                text: "Meeting Point Lobby of the hotel.",
                feePerPerson: "",
              },
              {
                text: "Snack, boat.",
                feePerPerson: "",
              },
              {
                text: "Descriptive: We start with the Old Town, which offers a unique sense of tradition and transition. Old buildings of three and four floors, with red tile roofs and wrought iron bars, around balconies adorned with plant pots, surround historic squares and impressive ruins. We continue with our tour of the financial district which is located in the heart of the city. Panama City until we reach Gamboa where we will take a boat where you will live a new experience and venture to navigate the Panama Canal, we will travel through Gatun Lake until we reach the famous monkey island where we will interact with different types of monkeys and you can enjoy of beautiful landscapes in the middle of the jungle then Later we will go to Embera, a small traditional town located on the banks of the Chagres river in the middle of the tropical jungle. The Emberas will show their traditions, their way of dressing and how they make their crafts. They will perform their traditional dances and music and we will have time to interact directly with them. We will enjoy lunch prepared by the Embera community Specialized guides.",
                feePerPerson: "",
              },
              {
                text: "Departure at 7:00 am / return at 14:00 pm.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl:
              "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 9.070385285702272,
            lng: -79.4506624,
          },
        ],
        gallery: [
          gallery1RGETravel,
          gallery2RGETravel,
          gallery3RGETravel,
          gallery4RGETravel,
        ],
        infoUrl:
          "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 8,
        slug: "monkey-adventures",
        bannerImg: bannerMonkeyAdventure,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Monkey Adventures",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Monkey Adventures",
            subtitle: "Monkey Adventures.",
            thirdtitle: "",
            infoUrl:
              "https://www.monkeyadventures.com.pa/copia-de-isla-grande-tour?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "Tour that combines the visit to Monkey Island and Sloth Sanctuary in the Panamanian Rainforest",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 9.075203527292032,
            lng: -79.65918303018104,
          },
        ],
        gallery: [
          gallery1MonkeyAdventure,
          gallery2MonkeyAdventure,
          gallery3MonkeyAdventure,
        ],
        gallery: [
          gallery1MonkeyAdventure,
          gallery2MonkeyAdventure,
          gallery3MonkeyAdventure,
        ],
        infoUrl:
          "https://www.monkeyadventures.com.pa/copia-de-isla-grande-tour?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 9,
        slug: "san-blas-dreams",
        bannerImg: bannerSanBlasDreams,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "San Blas Dreams",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "San Blas Dreams",
            subTitle: "",
            thirdTitle: "",
            infoUrl:
              "https://www.sanblasdreams.com/panama-stopover1.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1SanBlasDreams,
            title: "San Blas",
            subtitle: "This tour includes:",
            items: [
              {
                text: "Shared round trip ground transport from your accommodation in Panama City​​ to San Blas.",
                feePerPerson: "",
              },
              {
                text: "Boat rides to and from the port.",
                feePerPerson: "",
              },
              {
                text: "Lunch.",
                feePerPerson: "",
              },
              {
                text: "Snorkeling Equipment.",
                feePerPerson: "",
              },
              {
                text: "Tour of Yanis Island & Natural Pool (due to the restrictions it is not possible to visit other islands).",
                feePerPerson: "",
              },
              {
                text: "Indigenous entrance taxes.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duration of the tour: 12 hours."],
            discount: "",
            infoUrl:
              "https://www.sanblasdreams.com/panama-stopover1.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2SanBlasDreams,
            title: "Embera Community",
            subtitle: "This tour includes:",
            items: [
              {
                text: "Transportation from Airport to hotel and back to Embera Community.",
                feePerPerson: "",
              },
              {
                text: "Private tour of the Embera Community, we will cross the Rio Chagres aboard the famous Piragua.",
                feePerPerson: "",
              },
              {
                text: "Traditional lunch, tropical fruit salad & refreshments.",
                feePerPerson: "",
              },
              {
                text: "Taxes.",
                feePerPerson: "",
              },
              {
                text: "Spanish and English speaking guides.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duration of the tour: 6 to 7 hours"],
            discount: "Minimum 2 Adults",
            infoUrl:
              "https://www.sanblasdreams.com/panama-stopover1.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 8.978104863523583,
            lng: -79.52091783150577,
          },
        ],
        gallery: [
          gallery1SanBlasDreams,
          gallery2SanBlasDreams,
          gallery3SanBlasDreams,
        ],
        infoUrl:
          "http://rgebooking.com/promo?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 10,
        slug: "parque-metropolitano",
        bannerImg: bannerParqueMetropolitano,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Metropolitan Natural Park",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Metropolitan Natural Park",
            subtitle: "Metropolitan Natural Park.",
            thirdtitle: "",
            infoUrl:
              "https://parquenaturalmetro.wixsite.com/panama2021?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "The Metropolitan Natural Park is part of the National System of Protected Areas of Panama located within the city limits; considered as “Lung of Panama City” is the only tropical rainforest in Central America within a metropolis, located 10 minutes away from the city center. It has an area of approximately 232 hectares, with a rich biodiversity of flora and fauna. Also has attractions, with a total of 5.07 kilometers (3.2 miles) of trails, viewpoints such as the Cerro Cedro viewpoint at 156 masl, with a panoramic view of the city, the Canal and other protected areas that form a natural biological corridor.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "CONDITIONS AND RESTRICTIONS<br><br>Please reserve before your visit in the following website: <a href='https://survey123.arcgis.com/share/ae32a001539543e9b07d8fedbd6bcfd3' target='_blank'>https://survey123.arcgis.com/share/ae32a001539543e9b07d8fedbd6bcfd3</a> <br>When you visit the Park, remember to be a responsible visitor and follow our recommendations:<br>1. Follow the signs.<br>2. Stay on the trails.<br>3. Avoid touching the plants.<br>4. Do not hang from lianas.<br>5. The introduction or extraction of any organism (plants or animals) is prohibited.<br>6. No smoking.<br>7. Do not carve trees or draw structures.<br>8. Pets are not allowed.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.984870152823333,
            lng: -79.54679697383372,
          },
        ],
        gallery: [
          gallery1ParqueMetropolitano,
          gallery2ParqueMetropolitano,
          gallery3ParqueMetropolitano,
        ],
        infoUrl:
          "https://parquenaturalmetro.wixsite.com/panama2021?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      //Replace item start
      {
        id: 11,
        slug: "ancon-expeditions",
        bannerImg: AnconExpeditions.banner,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Ancon Expeditions",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Ancon Expeditions",
            subTitle: "",
            thirdTitle: "",
            infoUrl:
              "https://www.anconexpeditions.com/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: AnconExpeditions.tour.canal,
            title: "Panama City & The Panama Canal",
            subtitle: '',
            items: [{text: "Just one day in town? No problem! Enjoy the highlights within a short distance from Tocumen Airport, such as Panama City’s UNESCO World Heritage Sites Panama Viejo and Casco Viejo, Miraflores Locks Visitor Center at the Panama Canal, drive through the former Canal Zone, Biodiversity Museum, bike along Amador Causeway, or even participate in a hands-on cooking class with a young Panamanian chef!"}],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl: getURL('https://anconexpeditions.com/products/panama-city-the-panama-canal', true),
          },
          {
            img: AnconExpeditions.tour.selva,
            title: "Panama´s Spectacular Rainforest",
            subtitle: '',
            items: [{text: "Just 60 minutes from Tocumen Airport immerse yourself in the biodiversity of Panama’s tropical rainforest. Aboard an expedition boat explore the waters of the Panama Canal through coves and inlets in search of wildlife including countless species of birds, reptiles, and several species of monkeys. Enjoy a nature walk and hike up the Rainforest Discovery Center at Soberania National Park which boasts an impressive list of 525 species of birds and is also home to 105 species of mammals. Visit a sloth sanctuary and ecological exhibitions, glide through the rainforest on an aerial tram, or for the more active enjoy a canopy zipline adventure."}],
            feePerPerson: "",
            tips: [""],
            discount: "",
            infoUrl: getURL('https://anconexpeditions.com/products/panama-s-spectacular-rainforest', true),
          },
        ],
        map: [
          {
            lat: 8.981513624474385,
            lng: -79.5248345315058,
          },
        ],
        gallery: AnconExpeditions.gallery,
        infoUrl: getURL("https://www.anconexpeditions.com/", true),
      },
      //Replace item end
      {
        id: 12,
        slug: "hover-tours",
        bannerImg: bannerHoverTours,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Hover Tours",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Hover Tours",
            subTitle: "Hover Tours",
            thirdTitle: "",
            infoUrl:
              "https://hovertours.com.pa/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1HoverTours,
            title: "The three cities of Panama",
            subtitle: "",
            items: [
              {
                text: "Get ready to live an unforgettable experience during your visit to Panama. This tour takes us to know the monuments of Panama Viejo, founded in 1519 as the first city established by the Spanish on the American Pacific coast. Next, we will enjoy spectacular intermixed views of the modern city and the Old Town, the second city founded on the Pacific coast by the Spanish in 1673, which we will visit by walking through its narrow and charming streets. We finish our tour with an impressive view from the islands of the Amador Causeway.",
                feePerPerson: "",
              },
              {
                text: "Includes:",
                feePerPerson: "",
              },
              {
                text: "Round trip transfer from your hotel in the city.",
                feePerPerson: "",
              },
              {
                text: "Entrance to the Historic Monumental Complex of Panama La Vieja.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Not Included:"],
            discount: "Meals, personal expenses, tips.",
            infoUrl:
              "https://hovertours.com.pa/en/panama_stopover/the-three-cities-of-panama/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2HoverTours,
            title: "Discover The Enigms of the Valley",
            subtitle: "",
            items: [
              {
                text: "¡Come and discover with us what the Valley has to offer! The impressive volcanic landscapes of this beautiful Coclesan town, surrounded by natural wealth, cultural history and incredible biodiversity.",
                feePerPerson: "",
              },
              {
                text: "Includes:",
                feePerPerson: "",
              },
              {
                text: "Round trip transfer from your hotel in the city",
                feePerPerson: "",
              },
              {
                text: "Entrance to the Valley Visitor Center",
                feePerPerson: "",
              },
              {
                text: "Entrance to El Macho Jet",
                feePerPerson: "",
              },
              {
                text: "Entrance to the Pintyada Stone",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Not Included:"],
            discount: "Meals, personal expenses, tips.",
            infoUrl:
              "https://hovertours.com.pa/en/panama_stopover/discover-the-enigmas-of-the-valley/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 9.017659252025135,
            lng: -79.51760742698679,
          },
        ],
        gallery: [gallery1HoverTours, gallery2HoverTours, gallery3HoverTours],
        infoUrl:
          "https://hovertours.com.pa/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 13,
        slug: "voyair-pty",
        bannerImg: bannerVoyairPTY,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Voyair PTY",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Voyair PTY",
            subTitle: "Voyair PTY",
            thirdTitle: "",
            infoUrl:
              "https://voyairpty.com/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1VoyairPTY,
            title: "Rainforest Hike at the Soberania National Park",
            subtitle: "",
            items: [
              {
                text: "Accompanied by our bilingual guide, you will hike through a dense tropical canopy, looking out for birds, monkeys, butterflies, frogs and many others species while listening to the stories about the region’s incredible biodiversity.",
                feePerPerson: "",
              },
              {
                text: "The recommended trail is The Plantation Road (depending the day and season), a former cacao plantation land which includes both flat and hilly sections winding through a mature forest. The hike takes approximately 3 hours in order to walk between 7 to 10 kms (4 to 6 miles). This trail is a Bird watching paradise and a perfect get away from the city suitable for almost everyon.",
                feePerPerson: "",
              },
              {
                text: "Includes:",
                feePerPerson: "",
              },
              {
                text: "Private A/C Transport.",
                feePerPerson: "",
              },
              {
                text: "Bilingual guide.",
                feePerPerson: "",
              },
              {
                text: "One water bottle.",
                feePerPerson: "",
              },
              {
                text: "National Park entrance fee.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Not Included"],
            discount: "Breakfast or any other beverage or snack",
            infoUrl:
              "https://voyairpty.com/tour/rainforest-hiking-at-the-soberania-national-park?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2VoyairPTY,
            title: "HORSEBACK RIDING",
            subtitle: "",
            items: [
              {
                text: "Enjoy a horseback riding experience, meandering through green rolling hills, crossing rivers,  small streams and encounter beautiful landscapes.",
                feePerPerson: "",
              },
              {
                text: "After a brief safety and technique orientation, you will have more than a hour of guided horseback riding through the natural trails.  You can also take a swim in some of the clear water rivers.",
                feePerPerson: "",
              },
              {
                text: "A private ranch,  only 20 minutes away from the airport, is an ideal and pleasant destination  to explore riding in the company of a good horse. If you are good enough for galloping, there is plenty of space to do it and the horses naturally love it. The temperature is cooler than in Panama Cit.",
                feePerPerson: "",
              },
              {
                text: "Duration: 4 – 5 hours",
                feePerPerson: "",
              },
              {
                text: "INCLUDES:",
                feePerPerson: "",
              },
              {
                text: "Bottled water",
                feePerPerson: "",
              },
              {
                text: "1 Soda/Pop",
                feePerPerson: "",
              },
              {
                text: " **Air-conditioned vehicle** (if transfer option is selected)",
                feePerPerson: "",
              },
              {
                text: "Local guide",
                feePerPerson: "",
              },
              {
                text: "Hour and a half Horseback riding (approximately)",
                feePerPerson: "",
              },
              {
                text: "All taxes and Fees",
                feePerPerson: "",
              },
              {
                text: "OPTIONAL: BBQ LUNCH",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [""],
            discount:
              "Easy to moderate activity.  No experience needed it. Recommended minimum age is 9 years old. Children under 7 years old Free and have to ride with one of their parents. Maximum weight allowed 120 Kgs. Tour may be changed or cancel due to weather conditions. Tour duration: Approximately 5 hours. The staffs speaks English",
            infoUrl:
              "https://voyairpty.com/tour/horseback-riding?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 9.07451488191963,
            lng: -79.65982676034103,
          },
        ],
        gallery: [gallery1VoyairPTY, gallery2VoyairPTY, gallery3VoyairPTY],
        infoUrl:
          "https://voyairpty.com/es/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 14,
        slug: "trip-de-jenny",
        bannerImg: bannerTripJenny,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Trip de Jenny",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Trip de Jenny",
            subTitle: "Trip de Jenny",
            thirdTitle: "",
            infoUrl:
              "https://eltripdejenny.com/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1TripJenny,
            title: "Colon Extreme Adventure Full Day",
            subtitle: "",
            items: [
              {
                text: "If you thought that Colon is only sun, beach and sand, you are wrong … In the Caribbean we have vast nature and places to explore.",
                feePerPerson: "",
              },
              {
                text: "From pirate stories, trails steeped in military history, to launching a 700-meter long zipline over Gatun Lake, this is all this super day of adventures in Colón has to offer. In this trip, we will visit the Castillo San Lorenzo and La Granja Campo Aventura, in a single day.",
                feePerPerson: "",
              },
              {
                text: "Stop 1: Our first stop will be in the San Lorenzo protective forest, where we will walk the trails to understand the tropical nature. Upon arriving at Castillo San Lorenzo, we will travel back in time and talk about this important fortification during the Spanish colony",
                feePerPerson: "",
              },
              {
                text: "Stop 2: Colón is famous for its delicious cuisine. We will enjoy a Caribbean lunch prepared by locals. Get ready because they always surprise us with an extra specialty on the menu.",
                feePerPerson: "",
              },
              {
                text: "Stop 3: Be ready for our next stop for adventure.   Finally, arrive at La Granja Campo y Aventura to enjoy this circuit prepare just for you!.",
                feePerPerson: "",
              },
              {
                text: "Prepare our security equipment to ride adrenaline in a 700 mts zipline over the Gatún Lake, accept the challenge to cross five hanging bridge or just chill kayaking in the Gatun Lake to enjoy the tropical forest view",
                feePerPerson: "",
              },
              {
                text: "We depart back to Panama City at 4:00 p.m",
                feePerPerson: "",
              },
              {
                text: "INCLUDED:",
                feePerPerson: "",
              },
              {
                text: "Transportation from Panama City – Colón  round trip.",
                feePerPerson: "",
              },
              {
                text: "Guided hiking in San Lorenzo Castle main short trails. (3km).",
                feePerPerson: "",
              },
              {
                text: "Canopy at La Granja Campo y Aventura.",
                feePerPerson: "",
              },
              {
                text: "Kayak over Gatún Lake.",
                feePerPerson: "",
              },
              {
                text: "Caribbean Lunch.",
                feePerPerson: "",
              },
              {
                text: "Snacks, fruits, and water.",
                feePerPerson: "",
              },
              {
                text: "Organic / biodegradable mosquito repellent.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Does not include"],
            discount: "Alcohol beverages and souvenirs",
            infoUrl:
              "https://eltripdejenny.com/producto/colon-extreme-adventure-full-day/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2TripJenny,
            title: "Explore the Caribbean",
            subtitle: "",
            items: [
              {
                text: "If you like to enjoy the sea and its attractions but more relaxed. This trip is for you.",
                feePerPerson: "",
              },
              {
                text: "Enjoy a full day in Puerto Lindo. A small fisherman village surrounded by virgin and exotic nature of Colón.",
                feePerPerson: "",
              },
              {
                text: "Come to explore the Tunnel of Love, a natural tunnel formed by mangroves.",
                feePerPerson: "",
              },
              {
                text: "Finish in the relaxing crystalline waters of Mamey Island.",
                feePerPerson: "",
              },
              {
                text: "Complete your adventure with a Caribbean-style lunch: coconut rice, fresh fish, patacones and salad.",
                feePerPerson: "",
              },
              {
                text: "Includes:",
                feePerPerson: "",
              },
              {
                text: "Transportation Panama / Puerto Lindo round trip.",
                feePerPerson: "",
              },
              {
                text: "Visit to “Tunel del Amor”, Mamey Island, Natural Swimming Poo",
                feePerPerson: "",
              },
              {
                text: "Welcome Drink.",
                feePerPerson: "",
              },
              {
                text: "Caribbean lunch.",
                feePerPerson: "",
              },
              {
                text: "Water, Snacks and fruits aboard.",
                feePerPerson: "",
              },
              {
                text: "Snorkelling Kit.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Does not include:"],
            discount: "Alcohol beverages and souvenirs",
            infoUrl:
              "https://eltripdejenny.com/producto/explore-caribe-full-day-experience/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 9.359637941390362,
            lng: -79.89374823152576,
          },
        ],
        gallery: [gallery1TripJenny, gallery2TripJenny, gallery3TripJenny],
        infoUrl:
          "https://eltripdejenny.com/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 15,
        slug: "tree-trek-gamboa",
        bannerImg: bannerTreeTrekGamboa,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Tree Trek Gamboa",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Tree Trek Gamboa",
            subtitle: "Canopy en Gamboa",
            thirdtitle: "",
            infoUrl:
              "https://reservas.boquetetreetrek.com/gamboa/tours-207c8743-254a-4fff-9ca9-540fd95ac122/p/tour-canopy-gamboa?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description:
              "Travel through the cloud forest very close to Panama City in a different way, flying through the air up to 80 km through the forest through zipline. There are 10 lines and 13 platforms full of adrenaline and biodiversity. A tour full of joy, fauna and flora of the area that you cannot miss the opportunity not to do. We have 3 schedules from Friday to Sunday (9:30 am | 11:30 am | 2:30 pm)",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "This is not a private tour, other travelers may join.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.984870152823333,
            lng: -79.54679697383372,
          },
        ],
        gallery: [
          gallery1TreeTrekGamboa,
          gallery2TreeTrekGamboa,
          gallery3TreeTrekGamboa,
        ],
        infoUrl:
          "https://reservas.boquetetreetrek.com/gamboa/tours-207c8743-254a-4fff-9ca9-540fd95ac122/p/tour-canopy-gamboa?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 16,
        slug: "ecocircuitos",
        bannerImg: bannerEcocircuitos,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Ecocircuitos",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Ecocircuitos",
            subTitle: "Ecocircuitos",
            thirdTitle: "",
            infoUrl:
              "https://ecocircuitos.com/stopover/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1Ecocircuitos,
            title: "Panama Canal Partial Transit",
            subtitle: "",
            items: [
              {
                text: "The best way to experience the Panama Canal is in a cruise onboard the Pacific Queen. You will have the opportunity to appreciate the canal up close and to transit through the locks alongside the big ships. See the tugboats in operation and the Panama Canal expansion new set of locks!.",
                feePerPerson: "",
              },
              {
                text: "In the partial Panama Canal transit tour, navigate through the Miraflores and Pedro Miguel Locks. Pass through the Culebra Cut and underneath the Bridges of the Americas and the Centennial.",
                feePerPerson: "",
              },
              {
                text: "The tour includes:",
                feePerPerson: "",
              },
              {
                text: "Hotel pickup and dropoff (from selected hotels)",
                feePerPerson: "",
              },
              {
                text: "Lunch",
                feePerPerson: "",
              },
              {
                text: "Sodas and waters",
                feePerPerson: "",
              },
              {
                text: "Bilingual guide",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Duration: 5-6 hours approximately"],
            discount: "",
            infoUrl:
              "https://ecocircuitos.com/stopover/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2Ecocircuitos,
            title: "Kayaking the Panama Canal Watershed",
            subtitle: "",
            items: [
              {
                text: "Get unique insights of the Panama Canal Operations while paddling in the Chagres River and enjoy the amazing neotropical wildlife.",
                feePerPerson: "",
              },
              {
                text: "This tour begins at the private Gamboa Marina, where our expert naturalist guide will give you an introductory training in safety, use of paddles, and kayak handling. Shortly after, you will be paddling along the Chagres River basin, while you learn from our naturalist guide about the diverse flora and fauna that can be seen in the area, and of the importance of the Soberania National Park.",
                feePerPerson: "",
              },
              {
                text: "Duration: 3 hours (not including transfers to and from Gamboa)",
                feePerPerson: "",
              },
              {
                text: "What to bring: comfortable clothes that can get wet, sunscreen, cap, insect repellent",
                feePerPerson: "",
              },
              {
                text: "What it includes: Kayaks and all the gear, Naturalist Guide, Bottled Water, escort boat, snack.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Not included"],
            discount: "transfers to and from Gamboa.",
            infoUrl:
              "https://ecocircuitos.com/stopover/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [
          {
            lat: 8.995028517483584,
            lng: -79.50829740266965,
          },
        ],
        gallery: [
          gallery1Ecocircuitos,
          gallery2Ecocircuitos,
          gallery3Ecocircuitos,
        ],
        infoUrl:
          "https://ecocircuitos.com/stopover/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
      },
      {
        id: 17,
        slug: "scuba-panama",
        bannerImg: bannerScubaPanama,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Scuba Panama",
            indice_slug: null,
          },
        ],
        mainText: [
          {
            title: "Scuba Panama",
            subTitle: "",
            thirdTitle: "",
            infoUrl: "",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: card1ScubaPanama,
            title: "Atlantic dive - day tour",
            subtitle: "",
            items: [
              {
                text: "Diving certification is required for this tour.",
                feePerPerson: "",
              },
              {
                text: "$250 one diver / $165 two or more divers",
                feePerPerson: "",
              },
              {
                text: "Tour includes:",
                feePerPerson: "",
              },
              {
                text: "Ground transportation round trip from Panama City to Portobelo",
                feePerPerson: "",
              },
              {
                text: "Diving equipment",
                feePerPerson: "",
              },
              {
                text: "Two tanks",
                feePerPerson: "",
              },
              {
                text: "Dive Master",
                feePerPerson: "",
              },
              {
                text: "Dive boat",
                feePerPerson: "",
              },
              {
                text: "Lunch",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [
              "If you need snorkel equipment, you can rent it for an additional $15.",
            ],
            discount: "",
            infoUrl:
              "https://www.scubapanama.com/panama-tours.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: card2ScubaPanama,
            title: "Pacific dive tour",
            subtitle: "",
            items: [
              {
                text: "Diving certification is required for this tour.",
                feePerPerson: "",
              },
              {
                text: "$200 per diver for 4 or more divers",
                feePerPerson: "",
              },
              {
                text: "Tour includes:",
                feePerPerson: "",
              },
              {
                text: "Ground transportation round trip from Panama City to Portobelo",
                feePerPerson: "",
              },
              {
                text: "Diving equipment",
                feePerPerson: "",
              },
              {
                text: "Two tanks",
                feePerPerson: "",
              },
              {
                text: "Dive Master",
                feePerPerson: "",
              },
              {
                text: "Dive boat on both oceans",
                feePerPerson: "",
              },
              {
                text: "Lunch",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [
              "If you need snorkel equipment, you can rent it for an additional $15.",
            ],
            discount: "",
            infoUrl:
              "https://www.scubapanama.com/panama-tours.html?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
        ],
        map: [],
        gallery: [
          gallery1ScubaPanama,
          gallery2ScubaPanama,
          gallery3ScubaPanama,
        ],
        infoUrl: "",
      },
      //Replace line start
      {
        id: 18,
        slug: "panama-natural",
        bannerImg: PanamaAlNatural.banner,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Panama Natural",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Panama Natural",
            subtitle: "",
            thirdtitle: "",
            infoUrl: "",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: PanamaAlNatural.tour.canal,
            title: "Panama Canal + Causeway Amador half day tour",
            subtitle: "",
            items: [
              {
                text: "Daily departures: 9:00 a.m. and 2:00 p.m.",
                feePerPerson: "",
              },
              {
                text: "We begin our tour at the Miraflores visitor center, here we will be in front of the eighth wonder of the world, the Panama Canal, where we can admire the operation of the locks from the terrace while we watch the transit of the huge ships.",
                feePerPerson: "",
              },
              {
                text: "Then we will go to the Amador Causeway, four islands linked by a road built with the rocks that were extracted in the works of the Panama Canal. From here you can find the perfect spot to take pictures of the entrance to the canal and the panoramic view of Panama City.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [],
            discount: "",
            infoUrl:
              "https://panamaalnatural.com/our-destination/panama-canal-causeway/?utm_source=stopoverinpanama&utm_medium=referral&utm_campaign=stopoverinpanama",
          },
          {
            img: PanamaAlNatural.tour.ciudad,
            title: "Panama City: The Experience",
            subtitle: "",
            items: [
              {
                text: "We will visit the Archaeological Site of Panama Viejo, which corresponds to the seat where Panama City was founded on August 15, 1519 by Pedrarias Dávila and includes the ruins of the first European settlement on the American Pacific coast, as well as the vestiges of the first inhabitants of the isthmus. Declared a Historical Monumental Complex since 1976.",
                feePerPerson: "",
              },
              {
                text: "We will take an interesting tour of Modern Panama, to see its most emblematic skyscrapers such as the F&F Tower, better known as the Edificio del Tornillo, The Ocean Club Tower, Tower Financial Center, among many others that represent the cosmopolitan City of Panama.",
                feePerPerson: "",
              },
              {
                text: "We will continue our tour to visit the Old Town of Panama, Cultural Heritage of Humanity since 1982, enjoying its architecture, squares, the National Theater and the cobblestone streets that defy the relentlessness of time, it is the magical opportunity to have an encounter with more than four centuries of history.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: [
              "De necesitar equipo snorkel, lo puede alquilar por $15 adicionales.",
            ],
            discount: "",
            infoUrl:
              getURL("https://panamaalnatural.com/our-destination/panama-city-la-experiencia/", true),
          },
        ],
        map: [],
        gallery: PanamaAlNatural.gallery,
        infoUrl: getURL("https://panamaalnatural.com/our-destination/panama-canal-causeway/",true),
      },
      {
        id: 20,
        slug: "museo-de-la-mola",
        bannerImg: museoDeLaMola.banner,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "The Museo de la Mola",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "The Mueso de la Mola",
            subtitle: "",
            thirdtitle: "",
            infoUrl: "https://museodelamola.org/",
          },
        ],
        extraInfo: [
          {
            description:
              "The Museo de la Mola (MuMo) invites visitors to explore and learn about molas from their artistic aspect through its five exhibitions. The entrance and the guided visit to the museum are free. The guided tour is based on availability at the time of arrival.",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description:
                  "All MUMO visitors may enjoy an exclusive 20% discount on purchases in Attenza Duty Free, Tocumen International Airport;  by including the code received at the museum during the site shopping process in Attenza.net.",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "https://www.attenza.net",
          },
        ],
        cards: [],
        map: [
          {
            lat: 8.9522419,
            lng: -79.535641,
          },
        ],
        gallery: museoDeLaMola.gallery,
        infoUrl: "https://museodelamola.org/",
      },
      //Replace lines end
      {
        id: 21,
        slug: "poin-panama",
        bannerImg: poinPanama.banner,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Poin Panama",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Poin Panama",
            subtitle: "",
            thirdtitle: "",
            infoUrl: "https://www.poinpanama.com/en",
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: poinPanama.tour.border,
            title: "Brave the edge",
            subtitle: "",
            items: [
              {
                text: "Hover over 500 feet above the ground and get ready for the ultimate adventure in a unique and incredible place. From this point you can take the perfect photo with the modern and historical city in a different and fun way.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Discount code"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/en",
          },
          {
            img: poinPanama.tour.swing,
            title: "The Swing",
            subtitle: "",
            items: [
              {
                text: "Get ready to swing from the top of one of the highest buildings in Panama City. Push yourself and feel the adrenaline flowing through your veins but most importantly; don't forget to enjoy the view.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Discount code"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/en",
          },
          {
            img: poinPanama.tour.viewpoint,
            title: "Glass Floor Viewpoint + Virtual Tour",
            subtitle: "",
            items: [
              {
                text: "Stand on the first and only Glass Viewpoint in Panama City and the most impressive in Central America. Discover how cars pass under your feet while you see the modern city and the history of Panama from the same point, after that, enjoy a 360 virtual tour that will take you to explore the wonders of the City of Panama, with incredible corners and views.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Discount code"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/en",
          },
          {
            img: poinPanama.tour.zipline,
            title: "Zipline",
            subtitle: "",
            items: [
              {
                text: "The Zipline will meet all your expectations of flying over one of the most visited points in Panama City. This attraction certainly will raise the adrenaline of those lovers of the extreme. This entertainment space has been designed under the highest safety standards. so that all the people who want to fly feel that their dreams were made reality.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Discount code"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/en",
          },
          {
            img: poinPanama.tour.package,
            title: "Full Package",
            subtitle: "",
            items: [
              {
                text: "If you do not visit POIN you have not visited Panama City. Come and discover each of the activities with this complete package that will take you to know the modern city and the history of Panama City, in a different way from the same point.",
                feePerPerson: "",
              },
            ],
            feePerPerson: "",
            tips: ["Discount code"],
            discount: "COPASTOPOV",
            infoUrl: "https://www.poinpanama.com/en",
          },
        ],
        map: [
          {
            lat: 8.961454,
            lng: -79.5396003,
          },
        ],
        gallery: poinPanama.gallery,
        infoUrl: "https://www.poinpanama.com/en",
      },
      //add lines start
      {
        id: 22,
        slug: "go-panama-bike-tours",
        bannerImg: GoPanamaBikeTours.banner,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Go Panama Bike Tours",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Go Panama Bike Tours",
            subtitle: "",
            thirdtitle: "",
            infoUrl: getURL('https://gopanamabiketours.com', true),
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: GoPanamaBikeTours.tour.panamaNights,
            title: "Pananights",
            subtitle: '',
            items: [
              {text: "Discover the magic of Panama by night with our exciting tour, PANANIGHTS! Embark on a refreshing walking tour through Casco Antiguo, exploring the main squares while our expert guide shares the fascinating history behind each place. To conclude this unforgettable experience, we invite you to a rum tasting and other national beverages, where you can savor the authentic flavors of Panama."},
              {
                text: 'Duration: 2.5 to 3 hours',
              },
              {
                text: 'Availability: Wednesday to Saturday',
              },
              {
                text: 'Start Time: 7:30 Pm',
              },
              {
                text: '4 historic squares',
              },
              {
                text: '3 night time locations',
              },
              {
                text: 'Bilingual Local Guide',
              },
              {
                text: 'Alcoholic Beverages',
              },
              {
                text: 'Entrance fees to the locations',
              },
              {
                text: 'Panamanian Rum Tasting '
              }
            ],
            feePerPerson: "",
            tips: [],
            discount: "Mínimum 2 people",
            infoUrl: getURL('https://gopanamabiketours.com/pananights-copa-stop-over-one-day-tour/', true),
          },
          {
            img: GoPanamaBikeTours.tour.casco,
            title: "Bike Tour Around Panama City and Casco Antiguo",
            subtitle: '',
            items: [
              {text:"Discover Panama in a unique way with our bike tour! Join us and explore the most emblematic parts of the city as you pedal through the old town (Casco Antiguo) and enjoy the modern waterfront. Our expert guides will take you on an unforgettable journey, immersing you in Panama's rich history and vibrant culture. This bike experience will allow you to make the most of your time in this charming city. Don't miss this unique opportunity to explore Panama by bike!"},
              {
                text: 'Visit to Casco Antiguo, different squares, and markets',
              },
              {
                text: "Visit to the Cinta Costera and Panama's skyscrapers",
              },
              {
                text: 'Guided tour lasting 2.5 to 3 hours',
              },
              {
                text: 'New mountain bikes (Electric Bike with $10 Extra)',
              },
              {
                text: 'Helmet',
              },
              {
                text: 'Fresh water bottle',
              },
              {
                text: 'Bilingual guide',
              },
              {
                text: 'Meeting point: Go Panama Bike Tours - Casco Viejo',
              },
              {
                text: 'Availability: Every day at 9:30 AM and 3:30 PM',
              },
              {
                text: 'Duration: 2.5 to 3 hours',
              },
              {
                text: 'Level: Easy',
              },
            ],
            feePerPerson: "",
            tips: [],
            discount: "Minimum: 1 person",
            infoUrl: getURL('https://gopanamabiketours.com/bike-tour-one-day-tour/', true),
          }
        ],
        map: [
          {
            lat: 8.9525138,
            lng: -79.5360486,
          },
        ],
        gallery: GoPanamaBikeTours.gallery,
        infoUrl: getURL('https://gopanamabiketours.com', true),
      },
      {
        id: 23,
        slug: "our-panama",
        bannerImg: OurPanama.banner,
        breadcrumb: [
          {
            indice: "One day tours",
            indice_slug: "#anchor_tours",
          },
          {
            indice: null,
            indice_slug: null,
          },
          {
            indice: "Our Panama",
            indice_2_slug: null,
          },
        ],
        mainText: [
          {
            title: "Our Panama",
            subtitle: "",
            thirdtitle: "",
            infoUrl: getURL('https://www.ourpanama.com/', true),
          },
        ],
        extraInfo: [
          {
            description: "",
            open: "",
            social: [
              {
                name: "",
                url: "",
                text: "",
              },
            ],
            discount: [
              {
                description: "",
                code: "",
                last: "",
              },
            ],
            fees: "",
            website: "",
          },
        ],
        cards: [
          {
            img: OurPanama.tour.venas,
            title: "Historic Portobelo & Venas Azules",
            subtitle: "",
            items: [
              {
                text: 'Transportation from Panama City',
              },
              {
                text: 'Maritime Transfer',
              },
              {
                text: 'Caribbean Lunch with Beverage',
              },
              {
                text: 'Water in Tetra Pack',
              },
              {
                text: 'Entrance to Beaches and Surroundings',
              },
              {
                text: 'Snorkeling Equipment',
              },
              {
                text: 'Sloth Watching',
              },
              {
                text: 'Snorkeling Tour',
              },{
                text: 'Guided Tour of the Village with Explanation',
              },
            ],
            feePerPerson: "",
            tips: ['Trip Duration: Approximately 8 hours'],
            discount: "Minimum 2 persons",
            infoUrl: getURL('https://www.ourpanama.com/producto/la-historica-ciudad-de-portobelo-y-al-paraiso-del-caribe-venas-azules-privado', true),
          },
          {
            img: OurPanama.tour.guna,
            title: "San Blas day tour",
            subtitle: "",
            items: [
              {
                text: 'Shared 4x4 Land Transportation',
              },
              {
                text: 'Water Transportation',
              },
              {
                text: 'Lunch and one beverage',
              },
              {
                text: 'Port and island taxes',
              },
              {
                text: 'Bilingual local tour guide',
              },
              {
                text: 'Visit to 2 islands (Fragata and Perro Chico)',
              },
              {
                text: 'Natural Pool Tours',
              },
            ],
            feePerPerson: "",
            tips: ['Tour Duration: Approximately 8 hours'],
            discount: "Minimum 1 person",
            infoUrl: getURL('https://gopanamabiketours.com/es/bike-tour-one-day-tour/', true),
          }
        ],
        map: [
          {
            lat: 8.9888563,
            lng: -79.5310096,
          },
        ],
        gallery: OurPanama.gallery,
        infoUrl: getURL('https://www.ourpanama.com/', true),
      },
      //add lines end
    ],
  },
};

export default data;
